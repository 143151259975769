import React, { FC, useEffect, useState } from "react";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import FlightCard, { FlightCardNewProps } from "components/FlightCard/FlightCardNew";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { fetchFlights } from "../../Services/api";
import FlightCardNew from "components/FlightCard/FlightCardNew";
import extractFlightDetails from "Processes/flightListing";
import { useDispatch, useSelector } from "react-redux";
import { SelectFlightData, SelectFormFlightDetails, SelectUpdateFlightDetails, updateFlightData, updateFlightDetails, UpdateFormFlightDetails, updateStartDate } from "store/slice/DestinationSlice";
import { SERVICE_URL } from "config/api.url";

export interface SectionGridFilterCardProps {
  className?: string;
}

interface Flight {
  price: number;
  currency: string;
  type: string;
  flightWiseObject: Record<string, any>;
  OperatorName: string,
  flightInfo: Record<string, any>;
  TransitTime: string;
  transitArray: string[]
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {

  const dispatch = useDispatch()
  const [allFlightDetails, setAllFlightDetails] = useState<Flight[]>([])
  // const [flightDetails, setFlightDetails] = useState<Flight[]>([])
  const [cityTo, setCityTo] = useState("");
  const [cityFrom, setCityFrom] = useState(""); 
  const [loadState, setLoadState] = useState("We are searching Door2Door Flights for you");
  const [trip, setTripState] = useState("oneWay");
  const [guest, setGuestState] = useState(1);
  const locationSlice = useSelector(SelectFormFlightDetails);
  const [loader, setLoader] = useState(false)

  const updateFlightFlag = useSelector(SelectUpdateFlightDetails);
  const flightDetails= useSelector(SelectFlightData)
 

  useEffect(()=>{
    if(updateFlightFlag){
      getFlights()
      dispatch(updateFlightDetails(false))
    }
  },[updateFlightFlag])


  const getFlights=async()=>{
    setLoader(true)
    let toData = await localStorage.getItem("toData");
    let fromData = await localStorage.getItem("fromData");
    let guestAdults = localStorage.getItem("guestAdults") as string;
    let guestChildren = await localStorage.getItem("guestChildren") as string;
    let guestInfants = await localStorage.getItem("guestInfants") as string;
    let flightClassState = await localStorage.getItem("flightClassState") as string;
    let dropOffLocationType = await localStorage.getItem("dropOffLocationType") as string;
    let startDate = await localStorage.getItem("startDate") as string;
    let endDate = await localStorage.getItem("endDate") as string;

 

    const url = SERVICE_URL.flightSearch;

    let totalGuests: number = parseInt(guestAdults) + parseInt(guestInfants) + parseInt(guestChildren);
    setGuestState(totalGuests);
    setTripState(dropOffLocationType);
      const body = {
        "AdultCount": parseInt(guestAdults),
        "ChildCount": parseInt(guestChildren),
        "InfantCount": parseInt(guestInfants),
        "JourneyType": "OneWay",
        "PreferredAirlines": [
            ""
        ],
        "CabinClass": flightClassState,
        "Segments": [
            {
                "Origin":fromData, //fromData,
                "Destination":toData, //toData,
                "DepartureDate": startDate+"T00:00:00",
                "ReturnDate":`${endDate}T00:00:00`
            }
        ]
    }

    // const body = { "type": "F", "Origin": fromData, "Destination": toData } 
    const response = await fetchFlights(url, body)
    /// const response = {data:{FlightDataList:''}};
    if (response.data.FlightDataList) {
      const extractedFlightDetails = extractFlightDetails(response.data); 
      let fileObj=response?.data?.bodyObj?.Segments[0]; 
      setAllFlightDetails(extractedFlightDetails)                         //all flight details
      dispatch(updateFlightData(extractedFlightDetails))
      setLoadState("We are searching Door2Door Flights for you")
      // dispatch(UpdateFormFlightDetails({ ...locationSlice, startDate: new Date(startDate), endDate: new Date(endDate) })) 
      // dispatch(updateStartDate({startDateFlag:true})) 
     
       setLoader(false)
    } else {
      setLoader(false)
      setLoadState("Something Went Wrong")
    }

  }
  useEffect(() => { 
    console.log(flightDetails)
    if(flightDetails.length==0){
      getFlights() 
    } 
  }, []);
 
    
  

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      {/* <Heading2
        heading=" "
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {flightDetails.length} - Flights
            <span className="mx-2">·</span>
            {trip}
            <span className="mx-2">·</span>{guest} Guests
          </span>
        }
      /> */}
      <div className="mb-8 lg:mb-11">
        {/* <TabFilters /> */}
        {/* <TabFilters allFlightDetails={allFlightDetails} flightDetails={flightDetails} setFlightDetails={setFlightDetails} /> */}
      </div>
      <div className="lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
      {loader && <div className="flex mt-12 justify-center items-center">
          <ButtonPrimary loading>{loadState}</ButtonPrimary>
        </div>}
       {flightDetails.map((item:any, index:any) => (
          // <FlightCard defaultOpen={!index} key={index} data={item} />
          <FlightCardNew key={index} data={item} />

        ))}

      
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
