import SectionHero from "components/SectionHero/SectionHero";
import React, { useState, useEffect } from "react";
import { SERVICE_URL } from "config/api.url";
import './PageMobileDetails.css';


const PageSeat = () => {


    return (
        <div className="revTvlrTopSection " style={{ background: 'linear-gradient(rgba(0, 16, 55, 0.84), rgb(0, 139, 222))' }}>
            <div className="rtHeader"><span className="reviewBackIcon"></span>
                <p className="rtSubHead">Trip to</p>
                <p className="rtHead">Bengaluru</p>
            </div>
            <div className="rtFlightDtl">
                <div>
                    <div className="rtFlightDtlRow">
                        <div className="flexOne">
                            <p className="make_flex space_between alnItemCenter append_bottom15"><span
                                className="make_flex alnItemCenter"><span className="airline-icon-sctn multiAirline--leftAligned abc"><span
                                    src="https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi/I5.png?v=16"
                                    alt="airline"></span></span><b>Air
                                        India Express | <small>I5 - 330 | I5 - 975</small></b></span></p>
                            <div className="make_flex space_between append_bottom20">
                                <div className="flightsInfoDtl">
                                    <p className="font20 LatoBolder append_bottom5">22:10</p>
                                    <p className="font10 append_bottom8">Thu, 26 Oct 23</p>
                                    <p className="LatoBlack append_bottom3">New Delhi</p>
                                    <p className="font12">
                                        <span>Indira Gandhi International Airport Terminal 3</span>
                                    </p>
                                </div>
                                <div>
                                    <div className="text_center">
                                        <p className="append_bottom5 font14 LatoBold">5h 10m</p>
                                        <p className="append_bottom3"><span className="bgProperties stopsIcon"></span>
                                        </p>
                                        <p>1 stop</p>
                                    </div>
                                </div>
                                <div className="flightsInfoDtl text-right">
                                    <p className="font20 LatoBolder append_bottom5">03:20</p>
                                    <p className="font10 append_bottom8">Fri, 27 Oct 23</p>
                                    <p className="LatoBlack append_bottom3">Bengaluru</p>
                                    <p className="font12">
                                        <span >Bengaluru International Airport Terminal 2</span>
                                    </p>
                                </div>
                            </div>
                            <div className="layoverWrap append_bottom15">
                                <p className="make_flex alnItemStart append_bottom16"><span
                                    className="bgProperties icon16 append_right8"></span>
                                    <div className="make_flex column marL5"><span className="append_bottom5">
                                        <span color="#fff"><b>1h 40m</b> Layover at Pune</span>
                                    </span><span className="append_bottom5">
                                            <span color="#fff">00:15, 27 Oct - 01:55, 27 Oct</span>
                                        </span><span className="append_bottom5">
                                            <span color="#fff"><b>Plane change</b></span>
                                        </span></div>
                                </p>
                            </div>
                            <p className="make_flex space_between alnItemCenter"><span className="make_flex alnItemCenter">Economy &gt;
                                Xpress Value</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <p className="revTvlrWhiteCard append_bottom10">
                <p className="make_flex alnItemCenter space_between font18 LatoBlack blackTxt append_bottom20">Baggage Policy<span
                    className="blue-chevron"></span></p>
                <ul className="append_bottom10">
                    <li className="make_flex alnItemCenter blackTxt"><img
                        src="https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi/rt_rt3.png" width="24" height="24"
                        className="marR10" alt="baggageIcon" />
                        <div className="fullWidth make_flex"><span className="revTvlrBaggageInfo">Cabin bag</span><span dir="auto">7 Kgs (1
                            piece only)</span></div>
                    </li>
                    <li className="make_flex alnItemCenter blackTxt"><img
                        src="https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi/rt_rt4.png" width="24" height="24"
                        className="marR10" alt="baggageIcon" />
                        <div className="fullWidth make_flex"><span className="revTvlrBaggageInfo">Check-in bag</span><span dir="auto">15 Kgs
                            (1 piece only)</span></div>
                    </li>
                </ul>
                <p className="addBaggageStrip append_bottom10"><span
                    className="make_flex alnItemCenter flexOne"><img className="marR10"
                        src="https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi/rt_rt14.png?v=5" width="24"
                        height="24" alt="addBaggageIcon" /><span className="flexOne">Got excess luggage? Don't stress, buy extra
                            check-in baggage allowance at fab rates!</span></span><span className="baggageLink">+ADD</span></p>
            </p>
            <div className="revTvlrWhiteCard append_bottom10">
                <p className="make_flex alnItemCenter space_between append_bottom20" id="refundPolicy">
                    <div className="make_flex alnItemCenter">
                        <div className="font18 LatoBlack blackTxt">Cancellation Refund Policy</div>
                    </div><span className="blue-chevron"></span></p>
                <div className="cancellationTimeline append_bottom10">
                    <div className="makeFlex spaceBetween font10 latoBlack append_bottom15"><span>Cancel Between (IST)
                        :</span><span>Cancellation Penalty :</span></div>
                    <div className="makeFlex append_bottom10">
                        <div className="timelineList flexOne">
                            <div className="makeFlex spaceBetween end timelineListItem font11"><span><b>Now</b> - <b>22 Oct</b>,
                                22:10</span><span className="font14 latoBlack blackTxt">₹ 3,300</span></div>
                            <div className="makeFlex spaceBetween end timelineListItem font11"><span><b>22 Oct</b>, 22:10 - <b>26
                                Oct</b>, 18:10</span><span className="font14 latoBlack blackTxt">₹ 3,800</span></div>
                            <div className="makeFlex spaceBetween end timelineListItem font11"><span><b>26 Oct</b>, 18:10 - <b>26
                                Oct</b>, 22:10</span><span className="font14 latoBlack blackTxt">₹ 6,016</span></div>
                        </div>
                        <div className="timelineView">
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="revTvlrWhiteCard append_bottom10">
                <p className="font18 LatoBlack blackTxt append_bottom6">Unsure of your travel plans?</p>
                <p className="font14 blackTxt append_bottom10">
                    <span style={{color:"#4A4A4A"}}>Select a service to add flexibility to your trip</span>
                </p>
                <div className="services">
                    <div className="service prepend_top10 column"><span className="couponApplyTag append_bottom7"><span className="bgProperties couponIcon"></span>
                        <span style={{color:"#4A4A4A"}} ><b>Use MMTZC coupon code: Upto 50% OFF on ZC</b></span>
                    </span>
                        <div className="makeFlex row">
                            <div className="leftPnlServices">
                                <div className="servicesTitle"><input type="checkbox" />
                                    <p>
                                        <span style={{color:"#4A4A4A"}} >Add Zero Cancellation</span>
                                    </p>
                                </div>
                                <p className="subTitleSer"><b>Get refund of up to ₹ 6,016</b> in case of a cancellation up to 24hrs
                                    before departure.</p>
                                <p className="tncUrlOffer"><span>
                                    <span color="#008cff"> T&amp;Cs apply</span>
                                </span></p>
                            </div>
                            <div className="rightPnlServices"><img className="servicesImg"
                                src="https://imgak.mmtcdn.com/flights/assets/media/mobile/review/xxxhdpi/zc_icon_v2.png"
                                alt="ZC" />
                                <p><b>₹ 703</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="revZcPersu append_bottom8"><span
                        className="purpleText">Rs. 20.7 Lakhs have been saved by 683 travellers in the last 7 days with Zero
                        Cancellation.</span></div>
                </div>
            </div> */}
            {/* <div className="revTvlrWhiteCard append_bottom10 makeFlex spaceBetween">
                <div className="make_flex alnItemCenter space_between ">
                    <div className="make_flex alnItemCenter flexOne marR15"><span className="flexOne">Upgrade fare to enjoy additional benefits</span></div>
                    <div className="canclPanaltyText"><span className="baggageLink">UPGRADE</span></div>
                </div>
            </div> */}
            <div className="revTvlrWhiteCard append_bottom10 deals_section">
                <div className="make_flex alnItemCenter space_between font18 LatoBlack blackTxt append_bottom15"><span
                    className="make_flex alnItemCenter"><img
                        src="https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi/rt_rt8.png" width="24" height="24"
                        className="marR10" alt="applied coupon" /><span>Offers &amp; Promo Codes</span></span><span
                            className="blue-chevron"></span></div>
                <div className="make_relative review_traveller_deals" id="couponInput"><input type="text" name="coupon"
                    placeholder="Enter Coupon Code" className="rtOffersInput" autocomplete="off" value="" /><span
                        className="cpn-trigger disableCoupons">APPLY</span></div>
                <div className="prepend_top20">
                    <div className="rtOffersOuter radio deals_min_height make_relative   ">
                        <div className="make_flex flexOne">
                            <div className="marR15"><span className="customRadioBtn sizeSm primary "><input type="radio" value="" /><span
                                className="outer " ><span className="inner" ></span></span></span></div>
                            <div className="title-decs-wrpr">
                                <p className="blackTxt LatoBold font14 append_bottom3">MMTKOTAKCC</p>
                                <p className="tncUrlOffer"><span className="make_flex">Use this coupon and get Rs 721 instant discount on
                                    your flight booking with Kotak Bank Credit cards.</span><span>
                                        <font color="#008cff"> T&amp;Cs apply</font>
                                    </span></p>
                            </div>
                        </div>
                        <div className="make_flex column space_between make_relative "><img
                            src="https://imgak.mmtcdn.com/flights/assets/media/dt/review/IC.png?v=1" style={{ width: '24px', height: '24px' }}
                            alt="offers" /></div>
                    </div>
                    <div className="rtOffersOuter radio deals_min_height make_relative   ">
                        <div className="make_flex flexOne">
                            <div className="marR15"><span className="customRadioBtn sizeSm primary "><input type="radio" value="" /><span
                                className="outer "><span className="inner"></span></span></span></div>
                            <div className="title-decs-wrpr">
                                <p className="blackTxt LatoBold font14 append_bottom3">MMTOFFER</p>
                                <p className="tncUrlOffer"><span className="make_flex">Use this coupon and get Rs 238 Instant discount on
                                    your booking</span><span>
                                        <font color="#008cff"> T&amp;Cs apply</font>
                                    </span></p>
                            </div>
                        </div>
                        <div className="make_flex column space_between make_relative "><img
                            src="https://imgak.mmtcdn.com/flights/assets/media/dt/review/INSTANT.png?v=1" style={{ width: '24px', height: '24px' }}
                            alt="offers" /></div>
                    </div>
                    <div className="rtOffersOuter radio deals_min_height make_relative   ">
                        <div className="make_flex flexOne">
                            <div className="marR15"><span className="customRadioBtn sizeSm primary "><input type="radio" value="" /><span
                                className="outer "><span className="inner"></span></span></span></div>
                            <div className="title-decs-wrpr">
                                <p className="blackTxt LatoBold font14 append_bottom3">MMTUPI</p>
                                <p className="tncUrlOffer"><span className="make_flex">Use this code and get INR 225 instant discount on
                                    payments via UPI only.</span><span>
                                        <font color="#008cff"> T&amp;Cs apply</font>
                                    </span></p>
                            </div>
                        </div>
                        <div className="make_flex column space_between make_relative "><img
                            src="https://imgak.mmtcdn.com/flights/assets/media/dt/review/IC.png?v=1" style={{ width: '24px', height: '24px' }}
                            alt="offers" /></div>
                    </div>
                    <p className="text_center append_bottom10"><span className="blueTxt LatoBold text-uppercase">View more</span></p>
                </div>
            </div>
            <div className="rtWhiteCard traveller-container append_bottom10 " id="travellerContainerRT">
                <p className="rtCardHeading">Traveller Details</p>
                <p className="travellerPersuasion" ><span style={{ backgroundColor: 'rgb(227, 255, 249)' }}
                    className="bvc">NEW</span>
                    <span><b>Multiple travellers can now receive booking details</b></span>
                    <span color="#4A4A4A">&amp; other alerts by just adding their contact info!</span>
                </p>
                <div className="traveller-details-wrpr">
                    <div className="tvlrPaxInfoWrapper">
                        <div className="append_bottom10">
                            <p className="make_flex space_between alnItemCenter append_bottom15"><span
                                className="flexOne font14 defaultTxt make_flex alnItemCenter"><img
                                    src="https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi/rt_t7.png"
                                    className="marR10" width="24" height="24" alt="paxDataIcon" /><span className="LatoBold">ADULT (12
                                        yrs+)</span></span><span className="font12 defaultTxt text-red">0/1 added</span></p>
                            <div className="paxListWrapper append_bottom10"></div><span className="addPaxBtn blueTxt">+ ADD NEW ADULT</span>
                        </div>
                    </div>
                </div>
                <div className="gstInfoSection" id="gst-details-card"><label className="checkboxContainer "><span
                    className="commonCheckbox sizeSm primaryCheckbox"><input id="GST" type="checkbox" name="GST" value="" /><span
                        className="box "><span className="check"></span></span></span>
                    <div className="checkboxContent">
                        <p className="checkboxTitle">
                            <span>I have a GST number</span>
                            <span>(Optional)</span>
                        </p>
                    </div>
                </label></div>
                <div className="tvlrBookingInfoSection sentToSection" id="contact-info-card">
                    <p className="blackTxt LatoBlack font14 make_flex alnItemCenter append_bottom16"><span className="flexOne">Booking
                        details will be sent to</span><span className="blue-chevron rt_Arrow"></span></p>
                    <p className="defaultTxt font12 append_bottom16"><img alt="INFO_ICON"
                        src="https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi/rt_t3.png" className="marR10"
                        width="18" height="18" /><span dir="ltr">tyagidushyant08@gmail.com</span></p>
                    <p className="defaultTxt font12 append_bottom16"><img alt="INFO_ICON"
                        src="https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi/rt_t4.png" className="marR10"
                        width="18" height="18" /><span>
                            <span color="#008cff">Add Mobile number</span>
                        </span></p><span className="prepend_top5 block text-red"></span>
                </div>
            </div>
            <div className="rt_footer" id="black_footer">
                <div className="blackFooter" id="black_footer">
                    <div className="blackFooterLftDtls make_relative">
                        <p className="footerPrice"><span className="cc"><span>₹
                            6,016</span></span><span className="greyInfoIcon marL5 font12"></span></p>
                        <div className="makeFlex">
                            <p className="footerPriceTxt">FOR 1 ADULT</p><span className="marR5"></span>
                        </div>
                    </div>
                    <div className="black-btn-wrpr"><a className="fltBtn  btnCapsule   ">CONTINUE</a></div>
                </div>
            </div>
        </div>
    );
}

export default PageSeat;
