import React, { FC, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import { callPostMethod } from "Services/api";
import './FlightsDetailsPage.css';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { SERVICE_URL } from "config/api.url";
import PageSeat from "../PageSeat/PageSeat.jsx";
import { updateSeatAvialbliltyToken } from "store/slice/DestinationSlice";
import PageBaggage from "containers/PageBaggage/PageBaggage";
import PageMeal from "containers/PageMeal/PageMeal";

// import {fetchFlights} from "../../Services/api"

export interface FlightsDetailsPageProps {
    className?: string;
    data?: Object;
}

const FlightDetailsPageWeb: FC<FlightsDetailsPageProps> = ({
    className = "",
    data = {},
}) => {

    const dispatch = useDispatch();
    const [flightDestination, setFlightDestination] = useState<any>(null)
    const [priceDetails, setPriceDetails] = useState<any>(null);
    const [taxFare, setTaxFare] = useState<any>(0)
    const [token, setToken] = useState<any>(null)
    const flightDetailsVal = useSelector((state: any) => state.destinations.flightDetails)

    useEffect(() => {
        let data=  localStorage.getItem("flightDetails");
          if(data!=undefined && data!=null){
           let token = JSON.parse(data) 
            handleFlightDetails(token?.resultToken)
          } 
        }, [])

    const handleFlightDetails = async (e: string) => {
        let url = SERVICE_URL.getFlightPriceDetails;
        let payload = { "ResultToken": e }
        let res = await callPostMethod(url, payload);
        if (res?.status == 200) {
            let response = res?.data?.UpdateFareQuote?.FareQuoteDetails?.JourneyList;
            handlePriceBrakUpAdd(response?.Price?.PriceBreakup)
            setPriceDetails(response?.Price)
            let flightVal = Object.values(response?.FlightDetails?.Details)[0];
            setFlightDestination(flightVal)
            dispatch(updateSeatAvialbliltyToken(response?.ResultToken))
            setTimeout(() => {
                dispatch(updateSeatAvialbliltyToken(response?.ResultToken))
            }, 3000);
        }
    } 

    const handlePriceBrakUpAdd = async (value: any) => {
        let add = value?.Tax + value?.AgentTdsOnCommision + value?.AgentCommission;
        setTaxFare(add.toFixed(2));
    }

    //let data = localStorage.getItem("flightDetails");
    return (
        <div
            className={`nc-FlightsDetailsPage relative overflow-hidden ${className}`}
            data-nc-id="FlightsDetailsPage"
        >
            <Helmet>
                <title>Chisfis || Booking React Template</title>
            </Helmet>


            <div className="container relative">
                {/* SECTION HERO */}

                <div className="flightsContainer footerSpace">
                    <div className="makeFlex spaceBetween">
                        <div className="pageLeftConainer">
                            <form>
                                <div id="JOURNEY_SECTION" className="oneCard-element">
                                    <div className="componentContainer padding20 appendBottom20">
                                        <div>
                                            <div className="appendBottom20">
                                                <section className="flightDetailBlk undefined">
                                                    <div className="flDetailHdr">
                                                        <div>
                                                            <h2 className="blackFont"><b>{flightDetailsVal?.flightInfo?.start_obj?.AirportCode} → {flightDetailsVal?.flightInfo?.end_obj?.CityName}</b></h2>
                                                            <p className="appendTop10 makeFlex gap-x-10"><span className="scheduleDay"
                                                                style={{ backgroundColor: '#ffedd1' }}>Friday, Oct
                                                                6</span><span className="fontSize14">{flightDetailsVal?.type} · {flightDetailsVal?.flightInfo?.formattedTimeDifference}</span></p>
                                                        </div>
                                                        <div className="makeFlex column">
                                                            <p className="refundTag">Cancellation Fees Apply</p>
                                                            <p className="fontSize14 linkText appendTop10 textRight"><span className="fareViw">View Fare Rules</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="flightItenaryWrap 0">
                                                        <div className="flightItenaryHdr">
                                                            <div className="makeFlex gap-x-10"><span className="bgProperties icon24" ></span>
                                                                <p className="makeFlex hrtlCenter gap-x-10"><span
                                                                    className="fontSize14 boldFont">{flightDetailsVal?.OperatorName}</span><span
                                                                        className="fontSize14">QP 1411</span></p>
                                                            </div>
                                                            <div className="makeFlex">
                                                                <div className="makeFlex hrtlCenter">
                                                                    <span className="fontSize14">Economy &nbsp;
                                                                        <span className="green">SAVER</span></span>
                                                                    <span className="bgProperties icon16 appendLeft5 appendTop2"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flightItenary">
                                                            <div className="flexOne">
                                                                <div className="itenaryLeft">
                                                                    <div className="makeFlex gap-x-10 ">
                                                                        <div className="makeFlex time-info-ui"><span
                                                                            className="fontSize14 blackFont">{moment(flightDestination?.Origin?.DateTime).format('LT')   // 10:31 PM
                                                                            }</span><span
                                                                                className="layoverCircle"></span></div>
                                                                        <div><span className="fontSize14 blackFont">{flightDestination?.Origin?.CityName} </span><span
                                                                            className="fontSize14">. {flightDestination?.Origin?.AirportName}</span></div>
                                                                    </div>
                                                                    <div className="layover"><span className="fontSize14">{flightDetailsVal?.flightInfo?.formattedTimeDifference}</span></div>
                                                                    <div className="makeFlex gap-x-10 overideBg">
                                                                        <div className="makeFlex time-info-ui"><span
                                                                            className="fontSize14 blackFont">{moment(flightDestination?.Destination?.DateTime).format('LT')}</span><span
                                                                                className="layoverCircle"></span></div>
                                                                        <div><span className="fontSize14 blackFont">{flightDestination?.Destination?.CityName} </span><span
                                                                            className="fontSize14">. {flightDestination?.Destination?.AirportName}</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="itenaryRight">
                                                                <ul className="itenaryList">
                                                                    <li><span className="fontSize12">Baggage</span><span
                                                                        className="fontSize12">Check-in</span><span
                                                                            className="fontSize12">Cabin</span></li>
                                                                    <li><span className="fontSize12 blackFont">{flightDestination?.Attr?.Baggage}</span><span
                                                                        className="fontSize12 blackFont">15 Kgs (1 piece
                                                                        only)</span><span className="fontSize12 blackFont">{flightDestination?.Attr?.CabinBaggage}</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="baggageTag relative baggageArrowUp ">
                                                            <div className="baggageContent"><span className="icon20 bgProperties appendRight10"></span>
                                                                <p className="fontSize12 boldFont">Got excess baggage? Don’t stress, buy
                                                                    extra check-in baggage allowance for DEL-BOM at fab rates!</p>
                                                            </div>
                                                            <div><button type="button" className="addBtn">+ADD</button></div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            <div className=" reviewUpgradeFare appendTop20">
                                                <p className="blackText fontSize18 blackFont appendBottom5"></p>
                                                <p className="appendBottom15"></p>
                                                <div className="make_flex"></div>
                                            </div>
                                            {/* <div className="appendTop20">
                        <section className="refundSection">
                          <div>
                            <div className="refundHdr">
                              <div className="makeFlex hrtlCenter">
                                <h3 className="fontSize16 blackFont">
                                  Cancellation Refund Policy
                                </h3>
                              </div>
                              <div>
                                <p className="fontSize14 darkText textRight"><span
                                  className="linkText appendLeft3">View Policy</span></p>
                              </div>
                            </div>
                            <div className="flightDetails reviewCanPolicyWrapper">
                              <div className="cancSecWrap">
                                <p className="flightDetailsInfo makeFlex hrtlCenter"><span
                                  className="singleairline"><span className="arln-logo logo1"></span></span><span
                                      className="blackFont darkText appendLeft10">DEL-BOM</span></p>
                                <div className="timeLineDetailsInfo makeFlex ">
                                  <div className="cancInfoLeft">
                                    <p className="appendBottom20">Cancellation Penalty :</p>
                                    <p>Cancel Between (IST) :</p>
                                  </div>
                                  <div className="flexOne">
                                    <div className="makeFlex"><span className="cancPriceInfo fontSize16">₹
                                      3,900</span><span className="cancPriceInfo fontSize16">₹
                                        4,765</span></div>
                                    <div className="cancTimeline">
                                      <div className="cancTimeNode">
                                        <p className="blackFont">Now</p>
                                      </div>
                                      <div className="cancTimeNode">
                                        <p className="blackFont">6 Oct</p>
                                        <p className="fontSize12 boldFont">08:55</p>
                                      </div>
                                      <div className="cancTimeNode">
                                        <p className="blackFont">6 Oct</p>
                                        <p className="fontSize12 boldFont">10:55</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="refundUpgradeCard appendBottom10  ">
                              <div className="makeFlex hrtlCenter"><span className="appendRight10"><span
                                className="icon24 bgProperties"></span></span><span
                                  className="boldFont fontSize12">More fares with flexible refund &amp;
                                  date change policy available!</span></div>
                              <div><span className="linkText boldFont fontSize12 padding5">UPGRADE</span>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div id="HOTEL_BANNER_V4" className="appendBottom20">
                  <div>
                    <div className="secretDealBannerV2 "><span className="secretDealTagHead"><span
                        className="bgProperties secretDealIcon"></span>
                      Flyer Exclusive Deal
                    </span>
                      <div className="makeFlex spaceBtwCenter"><span className="hotelDealTitle">
                        Book a Flight &amp; unlock these offers
                      </span></div>
                      <div className="benefitsListWrap">
                        <div className="benefitsList" >
                          <p className="benefitsHead"><span className="secretDealTickIcon bgProperties"></span><b>
                              Exclusive rates on Select Properties in Mumbai
                            </b></p>
                          <div className="hotelListWrap">
                            <div className="hotelCrossSellList">
                              <img
                                src="https://r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201509301253485476-4131204-b50f10beb2c411e98ae70242ac110002.jpg"
                                width="81" height="52" alt="hotelImg" />
                              <div className="hotelistRight">
                                <div className="lh15">
                                  <p className="hcsHotelName">Taj Santacruz, Mumbai</p>
                                  <p className="htlStarRating">
                                    <span className="htlFilledStarRating"></span>
                                  </p>
                                </div>
                                <p className="htlPriceOuter"><span className="hcsFinalPrice">₹
                                  14,175</span><span className="hcsOfferPrice">
                                    <b>₹ 14,175</b>
                                  </span></p>
                              </div>
                            </div>
                            <div className="hotelCrossSellList">
                              <img
                                src="https://r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202211131045515112-6872324-db6fcc08e80d11ed86de0a58a9feac02.jpg"
                                width="81" height="52" alt="hotelImg" />
                              <div className="hotelistRight">
                                <div className="lh15">
                                  <p className="hcsHotelName">Novotel Mumbai International Airport</p>
                                  <p className="htlStarRating">
                                    <span className="htlFilledStarRating"></span>
                                  </p>
                                </div>
                                <p className="htlPriceOuter"><span className="hcsFinalPrice">₹
                                  10,349</span><span className="hcsOfferPrice">
                                    <span style={{color:"#CBCBCB"}}>₹ 11,499</span>
                                  </span></p>
                              </div>
                            </div>
                            <div className="hotelCrossSellList">
                              <img
                                src="https://r1imghtlak.mmtcdn.com/0382a704422611ec839e0a58a9feac02.jpeg"
                                width="81" height="52" alt="hotelImg" />
                              <div className="hotelistRight">
                                <div className="lh15">
                                  <p className="hcsHotelName">Aboo's Concord Galaxy</p>
                                  <p className="htlStarRating">
                                    <span className="htlFilledStarRating"></span>
                                  </p>
                                </div>
                                <p className="htlPriceOuter"><span className="hcsFinalPrice">₹ 3,559</span><span
                                  className="hcsOfferPrice">
                                  <span style={{color:"#CBCBCB"}}>₹ 4,999</span>
                                </span></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="benefitsList" ><span
                          className="bgProperties dealComboIcon"></span>
                          <p className="benefitsHead"><span className="secretDealTickIcon bgProperties"></span><b>
                          <span style={{color:"#0C58B4"}}>Extra 12% off</span> using code BOOKHOTEL
                            </b></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                                {/* <div id="IMP_INFO" className="oneCard-element">
                  <div className="componentContainer paddingTop20">
                    <div className="importantInfoTop">
                      <h2 className="fontSize18 blackFont appendBottom12">Important Information</h2>
                    </div>
                    <p className="blackFont fonSize makeFlex hrtlCenter appendLeft20"><span
                      className="bgProperties icon20 appendRight10"></span>Check
                      travel guidelines and baggage information below:</p>
                    <ul className="infoList">
                      <li><u><b>Carry no more than 1 check-in baggage and 1 hand baggage per passenger. If
                        violated, airline may levy extra charges.</b></u></li>
                      <li>Wearing a mask/face cover is no longer mandatory. However, all travellers are advised to
                        do so as a precautionary measure.</li>
                    </ul>
                  </div>
                </div> */}

                                {/* <div id="TRAVELLER_DETAIL" className="oneCard-element">
                  <div className="componentContainer appendTop15 appendBottom20 ">
                    <div className="travellerWrapper">
                      <div className="travellerHeading paddingTop20 appendBottom20">
                        <h2 className="fontSize18 blackFont ">Traveller Details</h2>
                      </div>
                      <div className="travellerLoginSection appendBottom20">
                        <div className="makeFlex perfectCenter">
                          <div className="appendRight10"><span className="loginUserImg bgProperties"></span>
                          </div>
                          <p className="fontSize14">Log in to view your <b>saved traveller list, unlock amazing
                            deals</b> &amp; much more!</p>
                        </div>
                        <div><button className="loginBtn">LOGIN NOW</button></div>
                      </div>
                      <div className="appendBottom20 " id="wrapper_ADULT">
                        <div className="adultDetailsHeading">
                          <div className="makeFlex perfectCenter">
                            <div className="appendRight10"><span className="adultImg bgProperties"></span>
                            </div>
                            <p className="fontSize14">
                              <span className="boldFont">ADULT (12 yrs+)</span>
                            </p>
                          </div>
                          <div className="makeFlex perfectCenter fontSize14 boldFont">
                          <span style={{color:"#4a4a4a"}}>0/1</span> 
                          <span style={{color:"#9b9b9b"}}>&nbsp;added</span>
                          </div>
                        </div>
                        <div className="alert-wrapper makeFlex appendBottom12 appendTop12 travellerSectionAlert">
                          <div className="makeFlex flexOne column">
                            <p className="darkText fontSize12 boldFont"><span className="alert-text"><b>Important:
                            </b>Enter name as mentioned on your passport or Government approved
                              IDs.</span></p>
                          </div>
                        </div>
                        <div className="adultListWrapper">
                          <div className="collapse"></div>
                          <div className="otherList"><span>You have not added any adults to the list</span></div>
                          <div className="otherList"><button type="button" className="addTravellerBtn">+ ADD NEW
                            ADULT</button></div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="bookingDetailsForm" id="contactDetails">
                        <p className="fontSize14 boldFont appendBottom15">Booking details will be sent to</p>
                        <div className="adultItemRow">
                          <div className="adultItem" id="Country Code"><label
                            className="makeFlex hrtlCenter">Country Code</label>
                            <div className="selectItem relative ">
                              <div className="selectList css-2b097c-container">
                                <div className="dropdown__control css-yk16xz-control">
                                  <div
                                    className="dropdown__value-container dropdown__value-container--has-value css-1hwfws3">
                                    <div className="css-1g6gooi">
                                      <div className="dropdown__input">
                                        <input type="text" value="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="adultItem" id="Mobile No" >
                            <div className="relative"><label >Mobile No</label><input placeholder="Mobile No" className="tvlrInput " type="text" value="" /></div>
                          </div>
                          <div className="adultItem" id="Email" >
                            <div className="relative"><label>Email</label><input 
                              placeholder="Email" className="tvlrInput " type="text" value="" /></div>
                          </div>
                        </div>
                      </div>
                      <div className="padding20" id="gstDetails"><label className="checkboxContainer "><span
                        className="commonCheckbox sizeSm primaryCheckbox"><input type="checkbox"
                          value="" /><span className="box "><span className="check"></span></span></span>
                        <div className="checkboxContent">
                          <p className="checkboxTitle"><span className="boldFont">
                          <span style={{color:"#000000"}}>I have a GST number</span>
                          <span style={{color:"#9b9b9b"}}>(Optional)</span>
                          </span></p>
                        </div>
                      </label></div>
                    </div>
                  </div>
                </div> */}
                                {/* <div id="BILLING_ADDRESS" className="appendBottom20">
                  <div data-cy="dt_gstn_container" className="billingDetailsWprDt billingDetailsContainer">
                    <div className="billingDetailsWpr__headingCtr">
                      <h2 data-cy="dt_gstn_header" className="billingDetailsWpr__heading">Your pincode and state</h2>
                      <p data-cy="dt_gstn_sub_header" className="billingDetailsWpr__subHeading">(Required for
                        generating your invoice.You can edit this anytime later in your profile section. )</p>
                    </div>
                    <div className="billingDetailsWpr__fieldsCtr">
                      <div className="labelInputWpr flexOne">
                        <p className="labelInputWpr__label">Pincode</p>
                        <div className="labelInputWpr__inputWithLbl"><input id="pincode_gst_info" type="text"
                           placeholder="Your Pincode" className="labelInputWpr__input "
                          data-cy="dt_pincode_gst_info" value="110027" /></div>
                      </div>
                      <div className="dropdownFieldWpr flexOne">
                        <p className="dropdownFieldWpr__label">State</p>
                        <div className="dropdownFieldWpr__inputWpr"><input id="dt_state_gst_info" type="text"
                           className="dropdownFieldWpr__input  " data-cy="dt_state_gst_info"
                          value="Delhi" /><span className="chevronWpr  down absChevron"></span></div>
                      </div>
                      <div className="labelInputWpr flexOne">
                        <p className="labelInputWpr__label">Address</p>
                        <div className="labelInputWpr__inputWithLbl"><input id="address_gst_info" type="text"
                           placeholder="Your Address (Optional)"
                          className="labelInputWpr__input " data-cy="dt_address_gst_info" value="" /></div>
                      </div>
                    </div>
                    <div className="checkboxWithLblWpr" data-cy="dt_cb_input_gst_info">
                      <div data-cy="dt_cb_input_container_gst_info" className="checkboxWithLblWpr__checkboxCtr"><span
                        className="checkboxWpr"><input id="cb_gst_info" type="checkbox" name="cb_gst_info"
                          className="checkboxWpr__input" /><b>&nbsp;</b></span></div>
                      <p data-cy="dt_cb_label_gst_info" className="checkboxWithLblWpr__label">
                        Confirm and save billing details to your profile</p>
                    </div>
                  </div>
                </div> */}
                                {/* <div id="FARE_LOCK" className="oneCard-element">
                  <div className="componentContainer makeFlex spaceBtwCenter padding20 appendTop15 appendBottom15">
                    <div className="makeFlex hrtlCenter">
                      <div className="roundBackground payLater makeFlex appendRight12"><span
                        className="bgProperties icon20"></span>
                      </div>
                      <div className="blackText">
                        <p className="blackFont fontSize18">Still unsure about this trip? <span
                          className="greenText">Lock this price!</span></p>
                        <p></p>
                      </div>
                    </div><button className="lato-black button buttonSecondry fontSize12">LOCK NOW</button>
                  </div>
                </div> */}
                                {/* <div className="makeFlex hrtlCenter spaceBetween appendTop15"><button
                  className="lato-black button buttonPrimary extraPadBtn fontSize16 ">Continue</button></div>
              </form>
              <form  id="mainSection_1">
                <div className="componentContainer appendTop15 disabled">
                  <div className="overviewSummaryHeading">
                    <h2 className="fontSize18 blackFont heading-text makeFlex gap-x-15"><span>Seats &amp; Meals</span>
                    </h2>
                  </div>
                </div>
              </form>
              <form  id="mainSection_2"> */}
                                {/* <div className="componentContainer appendTop15 disabled">
                  <div className="overviewSummaryHeading">
                    <h2 className="fontSize18 blackFont heading-text makeFlex gap-x-15"><span>Add ons</span><span
                      className="subHeading greenText">
                      <span style={{color:"#9c9d9f"}}>Airport Pick-up &amp; Drop, Covid Insurance, Charity</span>
                    </span></h2>
                  </div>
                </div> */}
                            </form>
                        </div>
                        <div className="pageRightContainer customScroll">
                            <div id="FARE_SUMMARY">
                                <section className="fareSummary">
                                    <div>
                                        <p className="fontSize18 blackFont">Fare Summary</p>
                                    </div>
                                    <div className="fareTypeWrap">
                                        <div className="fareRow">
                                            <div className="makeFlex hrtlCenter pointer flexOne"><span
                                                className="appendRight10 appendTop5"><span className="iconPlusImg bgProperties"></span></span><span
                                                    className="fareHeader">Base Fare</span></div><span className="fontSize14 darkText">{`${priceDetails?.Currency || "₹"} ${priceDetails?.PriceBreakup?.BasicFare.toFixed(2) || 0}`}</span>
                                        </div>
                                    </div>
                                    <div className="fareTypeWrap">
                                        <div className="fareRow">
                                            <div className="makeFlex hrtlCenter pointer flexOne"><span
                                                className="appendRight10 appendTop5"><span className="iconPlusImg bgProperties"></span></span><span
                                                    className="fareHeader">Taxes and Surcharges</span></div><span
                                                        className="fontSize14 darkText">{`${priceDetails?.Currency || '₹'} ${taxFare || 0}`}</span>
                                        </div>
                                    </div>
                                    <div className="fareFooter">
                                        <p className="fareRow"><span className="fontSize16 blackFont">Total Amount</span><span
                                            className="fontSize16 blackFont">{`${priceDetails?.Currency || "₹"} ${priceDetails?.TotalDisplayFare.toFixed(2) || 0}`}</span></p>
                                    </div>
                                </section>
                            </div>
                            {/* <div className="appendTop10">
                <section className="promocodeWrap ">
                  <div>
                    <div className="promoHeader">
                      <span className="fontSize18"><b>PROMO</b> CODES</span><span
                        className="promoIconWrapper appendLeft10"><span
                          className="bgProperties iconPromoImg iconPromocode"></span></span>
                    </div>
                    <div className="appendBottom15">
                      <p className="relative"><input type="text" className="promoInput"
                        placeholder="Enter promo code here" value="" /></p>
                    </div>
                    <div className="appendBottom20">
                      <p className="promoTitle"></p>
                      <div className="promoContent pointer ">
                        <div className="flexOne cpn-wrapper">
                          <div className="makeFlex pointer gap-x-5 "><span className="radioWrap"><span
                            className="block radio customRadioBtn"><span
                              className="customRadioBtn sizeSm primaryRadiobox "><input type="radio"
                                value="" /><span className="outer "><span
                                  className="inner"></span></span></span></span></span>
                            <div className="promoContentWrap flexOne"><span
                              className="couponCode">MMTDEAL</span><span className="promoCheckContent">Use
                                this coupon and get Rs 230 instant discount.</span><a
                                  href="https://www.makemytrip.com/promos/df-amazon-1500.html"
                                  className="fontSize12" target="_blank" rel="noreferrer">Terms &amp;
                                Conditions</a></div><span className="offerIcon bgProperties"></span>
                          </div>
                        </div>
                      </div>
                      <div className="promoContent pointer ">
                        <div className="flexOne cpn-wrapper">
                          <div className="makeFlex pointer gap-x-5 "><span className="radioWrap"><span
                            className="block radio customRadioBtn"><span
                              className="customRadioBtn sizeSm primaryRadiobox "><input type="radio"
                                value="" /><span className="outer "><span
                                  className="inner"></span></span></span></span></span>
                            <div className="promoContentWrap flexOne"><span
                              className="couponCode">MMTUPI</span><span className="promoCheckContent">Use this
                                code and get INR 254 instant discount on payments via UPI only.</span><a
                                  href="https://promos.makemytrip.com/mmt-upi-tncs-250823.html"
                                  className="fontSize12" target="_blank" rel="noreferrer">Terms &amp;
                                Conditions</a></div><span className="offerIcon bgProperties"></span>
                          </div>
                        </div>
                      </div>
                      <div className="promoContent pointer ">
                        <div className="flexOne cpn-wrapper">
                          <div className="makeFlex pointer gap-x-5 "><span className="radioWrap"><span
                            className="block radio customRadioBtn"><span
                              className="customRadioBtn sizeSm primaryRadiobox "><input type="radio"
                                value="" /><span className="outer "><span
                                  className="inner"></span></span></span></span></span>
                            <div className="promoContentWrap flexOne"><span className="couponCode">MMTZC</span><span
                              className="promoCheckContent">Use this coupon and get INR 150 discount on
                              Zero Cancellation product</span><a
                                href="https://www.makemytrip.com/promos/df-amazon-1500.html"
                                className="fontSize12" target="_blank" rel="noreferrer">Terms &amp;
                                Conditions</a></div><span className="offerIcon bgProperties"></span>
                          </div>
                        </div>
                      </div>
                      <div className="promoContent pointer ">
                        <div className="flexOne cpn-wrapper">
                          <div className="makeFlex pointer gap-x-5 "><span className="radioWrap"><span
                            className="block radio customRadioBtn"><span
                              className="customRadioBtn sizeSm primaryRadiobox "><input type="radio"
                                value="" /><span className="outer "><span
                                  className="inner"></span></span></span></span></span>
                            <div className="promoContentWrap flexOne"><span
                              className="couponCode">MMTAIRTEL</span><span className="promoCheckContent">Get
                                INR 250 Instant Discount on Airtel Payments Bank paymode + INR 250
                                cashback in Airtel Wallet</span><a
                                  href="https://www.makemytrip.com/promos/df-amazon-1500.html"
                                  className="fontSize12" target="_blank" rel="noreferrer">Terms &amp;
                                Conditions</a></div><span className="offerIcon bgProperties"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div> */}
                        </div>
                    </div>
                    <PageSeat />
                    <PageMeal />
                    <PageBaggage />
                </div>
                {/* SECTION */}

            </div>
        </div>
    );
};

export default FlightDetailsPageWeb;
