import { addHours } from "date-fns/esm";

interface Flight {
    price: number;
    currency: string;
    type: string;
    flightWiseObject: Record<string, any>;
    OperatorName :string,
    flightInfo:Record<string,any>,
    TransitTime: string; 
    transitArray:string[];
    resultToken:string
  }
  
  function formatTransitTime(minutes:number) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    return `${hours} hrs ${remainingMinutes} minutes`;
  }
  
  function calculateTransitTime(flightDetails: any) {
    for (let i = 0; i < flightDetails.length - 1; i++) {
      const currentFlight = flightDetails[i];
      const nextFlight = flightDetails[i + 1];
      const currentDateTime = new Date(currentFlight.Destination.DateTime);
      const nextOriginDateTime = new Date(nextFlight.Origin.DateTime);
      const transitTimeInMinutes = Math.floor((Number(nextOriginDateTime) - Number(currentDateTime)) / (1000 * 60));
      currentFlight.TransitTime = formatTransitTime(transitTimeInMinutes);
  
    }
  
    for (let i = 0; i < flightDetails.length; i++) {
  
      const currentFlight = flightDetails[i];
      const minutesDifference = Math.floor((Number(new Date(currentFlight.Destination.DateTime)) - Number(new Date(currentFlight.Origin.DateTime))) / (1000 * 60));
  
      currentFlight.TripTime = formatTransitTime(minutesDifference);
      console.log("currentFlight.Origin.DateTime + 3hr",);
      console.log("currentFlight.Origin.DateTime",currentFlight.Origin.DateTime);
      currentFlight.Origin.cabTime = addHours(currentFlight.Origin.DateTime,3);
      currentFlight.Origin.DayTimeDate = formatDate(currentFlight.Origin.DateTime);
      currentFlight.Destination.DayTimeDate = formatDate(currentFlight.Destination.DateTime);
      currentFlight.Destination.cabTime = addHours(currentFlight.Destination.DateTime,3);
    }
  
    return flightDetails;
  }
  
  
  function formatDate(dateTimeString: string): string {
    const dateTime = new Date(dateTimeString);
  
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];
  
    const dayOfWeek = days[dateTime.getUTCDay()];
    const month = months[dateTime.getUTCMonth()];
    const dayOfMonth = dateTime.getUTCDate();
    const time = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  
    return `${dayOfWeek}, ${month} ${dayOfMonth}  · ${time}`;
  }
  
  function extractFlightInfo(flightWiseObject: any): any {
    const keys = Object.keys(flightWiseObject);
    const startObj = {
      AirportCode: flightWiseObject[keys[0]].Origin.AirportCode,
      Time: flightWiseObject[keys[0]].Origin.DateTime.split(' ')[1]
    };
    startObj.Time = startObj.Time.substring(0, startObj.Time.length-3);
  
  
    
    const endObj = {
      AirportCode: flightWiseObject[keys[keys.length - 1]].Destination.AirportCode,
      CityName: flightWiseObject[keys[keys.length - 1]].Destination.CityName,
      Time: flightWiseObject[keys[keys.length - 1]].Destination.DateTime.split(' ')[1]
    };
    endObj.Time = endObj.Time.substring(0, endObj.Time.length-3);
  
  
    const startTime = new Date(flightWiseObject[keys[0]].Origin.DateTime);
    const endTime = new Date(flightWiseObject[keys[keys.length - 1]].Destination.DateTime);
    const timeDifferenceInMinutes = (endTime.getTime() - startTime.getTime()) / (1000 * 60);
  
    // Calculate hours and minutes
    const hours = Math.floor(timeDifferenceInMinutes / 60);
    const minutes = timeDifferenceInMinutes % 60;
  
    // Create the formatted time difference string
    const formattedTimeDifference = `${hours} Hr and ${minutes} Min`;
  
    return {
      start_obj: startObj,
      end_obj: endObj,
      timeDifferenceInMinutes: timeDifferenceInMinutes,
      formattedTimeDifference: formattedTimeDifference
    };
  }


  function extractFlightDetails(response: any): Flight[] {
  const ReturnJourneyList: Flight[] = [];
  if (response.FlightDataList && response.FlightDataList.JourneyList) {
    response.FlightDataList.JourneyList[0].forEach((journey: any, journeyIndex: number) => {

      const OneresJourney: Flight = {
        price:Math.round( journey.Price.TotalDisplayFare),
        currency: journey.Price.Currency,
        type: '',
        flightWiseObject: {},
        OperatorName:'',
        flightInfo:{},
        TransitTime: '',
        transitArray:[],
        resultToken:journey.ResultToken, 
      };

      const flightBasicDetails = calculateTransitTime(journey.FlightDetails.Details[0]);
      if (flightBasicDetails.length === 1) {
        OneresJourney.type = 'Nonstop';
      } else {
        OneresJourney.type = `${flightBasicDetails.length - 1} Stop`;
      }

      const flightWiseObject: Record<string, any> = {};
      flightBasicDetails.forEach((flight: any, index: number) => {
        flightWiseObject[(index + 1).toString()] = flight;
        if (flight.TransitTime) {
          OneresJourney.transitArray.push(`${flight.TransitTime} - ${flight.Destination.AirportCode}`);
        }
        OneresJourney.OperatorName = flight.OperatorName;
      });

      const flightInfo = extractFlightInfo(flightWiseObject);
      OneresJourney.flightInfo = flightInfo      
      OneresJourney.flightWiseObject = flightWiseObject;

      ReturnJourneyList.push(OneresJourney);
    });
  }

  return ReturnJourneyList;
}


export default extractFlightDetails