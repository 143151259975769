const BASE_URL=`https://stagingwebapi.door2doorflight.com/`;

export const SERVICE_URL={ 
    getFlightPriceDetails:`${BASE_URL}flight/flight-price`,
    findAirport:`${BASE_URL}flight/find-airport`,
    flightSearch:`${BASE_URL}flight/flight-search`, 
    flightSearch2:`${BASE_URL}flight/flight-search2`, 
    flightSeatSelection:`${BASE_URL}flight/flight-extra`, 
}

export const MAP_KEY=`AIzaSyAC4nlxHeX9PvtA-ZN9s6FJBgUYZ8UUdgw`;