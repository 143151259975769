import React, { FC, useState } from "react";
import spiceJetImg from '../../fligts-name-logo/SpiceJet.png'
import airIndiaImg from '../../fligts-name-logo/AI.png'
import vistaraImg from '../../fligts-name-logo/Vistara.svg'
import goAirImg from '../../fligts-name-logo/GO FIRST.png'
import ButtonPrimary from "shared/Button/ButtonPrimary";
import FlightsDetailsPage from "containers/FlightsDetailsPage/FlightsDetailsPage";
import { useNavigate } from "react-router-dom";
import { setFlightDetails } from "store/slice/DestinationSlice";
import { useDispatch, useSelector } from "react-redux";
import { SelectFormFlightDetails, UpdateFormFlightDetails } from "store/slice/DestinationSlice";
import { callPostMethod } from "Services/api";
import { SERVICE_URL } from "config/api.url";
import { updateBaggageData, updateMealData, updateMealItemVal, updateSeatData } from "store/slice/seatSelectionSlice";



export interface FlightCardNewProps {
  className?: string;
  defaultOpen?: boolean;
  data: {
    price: number;
    currency: string;
    type: string;
    flightWiseObject: Record<string, any>;
    OperatorName: string,
    flightInfo: Record<string, any>,
    TransitTime: string,
    transitArray: string[]
  }
}

const FlightCardNew: FC<FlightCardNewProps> = ({
  className = "",
  data,
  defaultOpen = false,

}) => {
  let imagePath: string
  if (data.OperatorName == 'SpiceJet') {
    imagePath = spiceJetImg;
  } else if (data.OperatorName == 'Air India') {
    imagePath = airIndiaImg;
  } else if (data.OperatorName == 'Vistara') {
    imagePath = vistaraImg;
  } else if (data.OperatorName == 'GO FIRST') {
    imagePath = goAirImg;
  } else {
    imagePath = ''
  }
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const locationSlice = useSelector(SelectFormFlightDetails)
  const [loadState, setLoadState] = useState("loading...");
  const [loaderFlag, setLoaderFlag] = useState(false)


  const renderTransmitDetails = (obj: any) => {

    return (
      <>
        {obj.TransitTime ? (
          <div className="my-7 md:my-10 space-y-5 md:pl-24">
            <div className="border-t border-neutral-200 dark:border-neutral-700" />
            <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
              Transit time: {obj.TransitTime}  -  {obj.Destination.CityName} ( {obj.Destination.AirportCode})

            </div>
            <div className="border-t border-neutral-200 dark:border-neutral-700" />
          </div>) : (<></>)}
      </>
    )
  }
  const renderFlightDetails = (flight: any) => {
    return (
      <>


        <div className="flex flex-col md:flex-row ">
          {/* logo */}
          <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
            {/* {=====================TODO===================} */}
            {/* <img src="https://www.gstatic.com/flights/airline_logos/70px/SQ.png" className="w-10" alt="" /> */}
            <img src={imagePath} className="w-10" alt="" />

          </div>
          {/* logo end */}
          <div className="flex my-5 md:my-0">
            <div className="flex-shrink-0 flex flex-col items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-10 text-sm">
              <div className="flex flex-col space-y-1">
                <span className=" font-semibold">
                  <img src="https://www.primetimeshuttle.com/wp-content/themes/primetimeshuttle/images/services/private-van.webp" style={{ width: '50px', height: '50px' }} />
                  {/* TODO =================DYNAMIC HARDCODED================= */}
                  {locationSlice?.origin?.address} ({locationSlice?.origin?.cabTime})
                  {/* Tokyo International Airport (HND) */}
                </span><br />
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {flight.Origin.DayTimeDate}
                </span>


                <span className=" font-semibold">
                  {/* TODO =================DYNAMIC HARDCODED================= */}
                  {flight.Origin.AirportName} Airport - ( {flight.Origin.AirportCode} )
                  {/* Tokyo International Airport (HND) */}
                </span>

              </div>
              <div className="flex flex-col space-y-1">


                <span className=" text-neutral-500 dark:text-neutral-400">
                  {flight.Destination.DayTimeDate}
                </span>

                <span className=" font-semibold">
                  {/* TODO =================DYNAMIC HARDCODED================= */}
                  {/* Singapore International Airport (SIN) */}
                  {flight.Destination.AirportName} Airport -( {flight.Destination.AirportCode} )

                </span><br />

                <span className=" font-semibold">
                  <img src="https://www.primetimeshuttle.com/wp-content/themes/primetimeshuttle/images/services/private-van.webp" style={{ width: '50px', height: '50px' }} />
                  {locationSlice?.destination?.address} ({locationSlice?.destination?.cabTime})
                </span>

              </div>
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
          <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
            <li>Trip time: {flight.TripTime}</li>
            <li>ANA · Economy class · {flight.OperatorCode} {flight.FlightNumber}</li>
          </ul>
        </div>

      </>

    );
  }
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const ParentComponent = async (data: any) => {
    dispatch(setFlightDetails(data))
    localStorage.setItem("flightDetails", JSON.stringify(data));
    setLoaderFlag(true)
    handleFlightDetails(data?.resultToken)
  }

  const handleFlightDetails = async (e: string) => {
    let url = SERVICE_URL.getFlightPriceDetails;
    let payload = { "ResultToken": e }
    let res = await callPostMethod(url, payload);
    if (res?.status == 200) {
      let response = res?.data?.UpdateFareQuote?.FareQuoteDetails?.JourneyList;
      handleGetSeatData(response?.ResultToken)

    }
  }

  const handleGetSeatData = async (e: String) => {
    let payload = {
      ResultToken: e
    }
    // let data = res?.data?.ExtraServices?.ExtraServiceDetails?.Meals;
    //         console.log(data)
    //         if (data !== undefined && data != null) {
    //             setMealSelection(data);
    //             setMealData(data[0]);
    //         }
    let res = await callPostMethod(SERVICE_URL.flightSeatSelection, payload);
    if (res?.data?.Status == 1) {
      let data = res?.data?.ExtraServices?.ExtraServiceDetails?.Seat;
      let mealData = res?.data?.ExtraServices?.ExtraServiceDetails?.Meals;
      let BaggageData = res?.data?.ExtraServices?.ExtraServiceDetails?.Baggage;
      
      if (mealData !== undefined && mealData != null) {
         console.log(mealData[0])
         dispatch(updateMealData({mealData:mealData[0]}));
        dispatch(updateMealItemVal({selectMealItem:mealData}))
      }


      if (BaggageData !== undefined && BaggageData != null) {
        let bagTempArra:any= []

        BaggageData.forEach((item:any) => {
          // Loop through the keys of the inner object
          for (const key in item) {
              if (item.hasOwnProperty(key)) {
                  const baggageInfo = item[key]; 
                  bagTempArra.push({
                    BaggageId: baggageInfo.BaggageId,
                    Origin: baggageInfo.Origin,
                    Destination: baggageInfo.Destination,
                    Price: baggageInfo.Price,
                    Weight: baggageInfo.Weight,
                    Code: baggageInfo.Code,
                  }) 
              }
          }
      });
          dispatch(updateBaggageData(bagTempArra));
      }
     
      if (data != undefined && data != null) {
       data = data[0];
       let arr = []
        for (var i = 0; i < data.length; i++) {
          let arr1 = [];
          for (var k = 0; k < data[i].length; k++) {
            arr1.push({ ...data[i][k], checked: false })
          }
          arr.push(arr1)
        }
        dispatch(updateSeatData(arr))
      }
      setLoaderFlag(false) 
      navigate("/checkout");
    } else {
      setLoaderFlag(false)
      navigate("/checkout"); 
    }
  }





  const renderDetails = () => {
    if (!isOpen) return null;
    return (
      <div>



        {Object.keys(data.flightWiseObject).map((key: string) => (
          <div>
            <div key={key} className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl">
              {renderFlightDetails(data.flightWiseObject[key])}
            </div>
            {renderTransmitDetails(data.flightWiseObject[key])}

          </div>

        ))}
      </div>
    );
  }
  return (
    <>
      {loaderFlag && <div className="flex mt-12 items-center" style={{ margin: "0 auto" }}>
        <ButtonPrimary loading>{loadState}</ButtonPrimary>
      </div>}
      <div
        className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
        data-nc-id="FlightCard"
      >

        <div
          className={` sm:pr-20 relative  ${className}`}
          data-nc-id="FlightCard"
        >
          {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a href="##" className="absolute inset-0" />

          <span
            className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
              }`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <i className="text-xl las la-angle-down"></i>
          </span>

          <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
            {/* LOGO IMG */}
            <div className="w-24 lg:w-32 flex-shrink-0">
              {/* <img src="https://www.gstatic.com/flights/airline_logos/70px/SQ.png" className="w-10" alt="" /> */}
              <img src={(imagePath)} className="w-10" alt="" />
              <span></span>
            </div>

            {/* FOR MOBILE RESPONSIVE */}
            <div className="block lg:hidden space-y-1">
              <div className="flex font-semibold">
                <div>
                  {/* origin  time */}
                  <span>{data.flightInfo.start_obj.Time}</span>
                  {/* origin loc start*/}
                  <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                    {data.flightInfo.start_obj.AirportCode}
                  </span>
                </div>
                <span className="w-12 flex justify-center">
                  <i className=" text-2xl las la-long-arrow-alt-right"></i>
                </span>
                <div>
                  {/* destination  time */}
                  <span>{data.flightInfo.end_obj.Time}</span>
                  {/* destination  location */}
                  <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                    {data.flightInfo.end_obj.AirportCode}
                  </span>
                </div>
              </div>

              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                <span className="VG3hNb">{data.type}</span>
                <span className="mx-2">·</span>
                {/* total time take by flight */}
                <span>{data.flightInfo.formattedTimeDifference}</span>
                <span className="mx-2">·</span>
                <span>{data.flightInfo.end_obj.AirportCode}</span>
              </div>
            </div>

            {/* TIME - NAME */}
            <div className="hidden lg:block  min-w-[150px] flex-[4] ">
              <div className="font-medium text-lg">{data.flightInfo.start_obj.Time} - {data.flightInfo.end_obj.Time}</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {data.OperatorName}
                {/* {data.flightWiseObject[1].OperatorName} */}
              </div>
            </div>

            {/* TIMME */}
            <div className="hidden lg:block flex-[4] whitespace-nowrap">
              <div className="font-medium text-lg">{data.flightInfo.start_obj.AirportCode} - {data.flightInfo.end_obj.AirportCode}</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {data.flightInfo.formattedTimeDifference}
              </div>
            </div>

            {/* TYPE */}
            <div className="hidden lg:block flex-[4] whitespace-nowrap">
              <div className="font-medium text-lg">{data.type}</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {(data.type == "Nonstop") ? "" :
                  <>
                    {data.transitArray.map((time, index) => (
                      <div key={index}>{time}</div>
                    ))}
                  </>}
              </div>
            </div>

            {/* PRICE */}
            <div className="flex-[4] whitespace-nowrap sm:text-right">
              <div style={{ paddingRight: '40px' }}>
                <span className="flex-[6] text-xl font-semibold text-secondary-6000">
                  {/* Flight Price */}
                  $ {data.price}
                </span>
              </div>
              <div className="flex-[6] text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
                <ButtonPrimary onClick={() => ParentComponent(data)}>Book Now</ButtonPrimary>
                {/* round-trip */}
              </div>
            </div>
          </div>
        </div>

        {/* DETAIL */}
        {renderDetails()}

      </div>
    </>
  );
};

export default FlightCardNew;
