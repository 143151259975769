import { useState, useMemo, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { useSelector } from "react-redux";
import { SelectStartDateFlag } from "store/slice/DestinationSlice";
export default function AutoSerachLocation({ setSelected, autoFocus = false,
  placeHolder = "Location ..",
  desc = "Where are you going?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5", autoValue = "" }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAC4nlxHeX9PvtA-ZN9s6FJBgUYZ8UUdgw",
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map mapvalue={autoValue} />;



  function Map({ mapvalue }) {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete();
    //await delay(1000);
    const startDateFlag = useSelector(SelectStartDateFlag)

    useEffect(() => {
      setValue(mapvalue, false)
    }, [mapvalue, startDateFlag]);

    useEffect(() => {
      onMapLoad()
    }, []);

    const onMapLoad = map => {
      navigator?.geolocation.getCurrentPosition(
        ({ coords }) => {
         console.log(coords)
        }
      );
      
    };
  


    const handleSelect = async (address) => {
      setValue(address, false);
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setSelected({ lat, long: lng, distance: 500, address: address });
    };





    return (
      <div>
        <Combobox onSelect={(e) => handleSelect(e)}>
          <ComboboxInput
            value={value}
            onChange={(e) => setValue(e.target.value)}
            disabled={!ready}
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            style={{ paddingLeftL: "10%" }}

          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
          <ComboboxPopover>
            <ComboboxList style={{ position: 'relative', zIndex: '99999999999', background: '#fff' }}>
              {status === "OK" &&
                data.map(({ place_id, description }) => (
                  <ComboboxOption key={place_id} value={description} />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    )
  }
}