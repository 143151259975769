import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { TaxonomyType } from "data/types";
import React, { FC ,useEffect} from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import HeroSearchFormSmall from "components/HeroSearchFormSmall/HeroSearchFormSmall";
// import {fetchFlights} from "../../Services/api"

export interface ListingFlightsPageProps {
  className?: string;
}

const ListingFlightsPage: FC<ListingFlightsPageProps> = ({
  className = "",
}) => {
  // async function getFlights(){
  //   console.log("HERE===> Calling")
  //   const res = await fetchFlights()
  //   console.log("🚀 ~ file: ListingFlightsPage.tsx:19 ~ getFlights ~ res:", res.data)
  // }
  // useEffect(() => {
  
  //   getFlights()

  // }, []);
  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Chisfis || Booking React Template</title>
      </Helmet>
      <BgGlassmorphism />
      
      <div className="container relative">
        <div className="mhide">
        <HeroSearchFormSmall />
        </div>
      
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentTab="Flights"
          listingType={
            <>
              <i className="text-2xl las la-plane-departure"></i>
              <span className="ml-2.5">1599 flights</span>
            </>
          }
          className="pt-10"
        />

        {/* SECTION */}
        <SectionGridFilterCard className="pb-24 lg:pb-28" />

      </div>
    </div>
  );
};

export default ListingFlightsPage;
