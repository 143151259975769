import React, { useEffect, useState } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import DatesRangeInput from "../DatesRangeInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import converSelectedDateToString from "utils/converSelectedDateToString";
import AutoSerachLocation from "../../HeroSearchForm/AutoSerachLocation";
import { callPostMethod } from "Services/api";
import { useDispatch, useSelector } from "react-redux";
import { SelectFormFlightDetails, UpdateFormFlightDetails } from "store/slice/DestinationSlice";
import { SERVICE_URL } from "config/api.url";
import { addDays } from "date-fns";

const FlightSearchForm2 = () => {
  const locationSlice = useSelector(SelectFormFlightDetails);
  const dispatch = useDispatch()
  //
  const [fieldNameShow, setFieldNameShow] = useState<
    "locationPickup" | "locationDropoff" | "dates" | "guests" | "general"
  >("locationPickup");
  //
  const [locationInputPickUp, setLocationInputPickUp] = useState("");
  const [locationInputDropOff, setLocationInputDropOff] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(new Date("2023/02/06"));
  const [endDate, setEndDate] = useState<Date | null>(new Date("2023/02/23"));

  const [dropOffLocationType, setDropOffLocationType] = useState<
    "Round-trip" | "One-way" | ""
  >("Round-trip");
  const [flightClassState, setFlightClassState] = useState("Economy");

  localStorage.setItem("flightClassState", flightClassState);
  localStorage.setItem("dropOffLocationType", dropOffLocationType);

  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: 1,
    guestChildren: 0,
    guestInfants: 0,
  });

  useEffect(() => {
     if (locationSlice?.startDate != null || locationSlice?.endDate !== null || locationSlice?.guestDetails !== null) {
      setLocationInputPickUp(locationSlice?.origin?.address)
      setLocationInputDropOff(locationSlice?.destination?.address)
      setStartDate(new Date(locationSlice?.startDate));
      setEndDate(new Date(locationSlice?.endDate))
      setGuestInput({
        guestAdults: locationSlice?.guestDetails?.guestAdults,
        guestChildren: locationSlice?.guestDetails?.guestChildren,
        guestInfants: locationSlice?.guestDetails?.guestInfants
      })

    }
  }, [])

  useEffect(() => {
   console.log({ startDate: addDays(new Date(),7), endDate: addDays(new Date(),10) })
    dispatch(UpdateFormFlightDetails({ ...locationSlice, startDate: addDays(new Date(),7), endDate: addDays(new Date(),10) }))
     }, [])

  localStorage.setItem("guestInfants", String(guestInput.guestInfants));
  localStorage.setItem("guestAdults", String(guestInput.guestAdults));
  localStorage.setItem("guestChildren", String(guestInput.guestChildren));

  const renderInputLocationPickup = () => {

    const handleFromData = async (e: any) => {
      setLocationInputPickUp(e.address);

      let url = SERVICE_URL.findAirport;
      let res = await callPostMethod(url, e);
      if (res.status == 200) {

        let response = res?.data[0]?.iata_code
        console.log(response)
        localStorage.setItem("fromData", response)
        dispatch(UpdateFormFlightDetails({ ...locationSlice, origin: e }))

      }
    }

 

    const isActive = fieldNameShow === "locationPickup";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationPickup")}
          >
            <span className="text-neutral-400">Current Address</span>
            <span>{locationInputPickUp || "Current Address"}</span>
          </button>
        ) : (
          /* <LocationInput
          //   headingText="Pick up?"
          //   defaultValue={locationInputPickUp}
          //   onChange={(value) => {
          //     setLocationInputPickUp(value);
          //     setFieldNameShow("dates");
          //   }}
          // /> */
          <div style={{ padding: '15px' }}><AutoSerachLocation setSelected={(e: number) => handleFromData(e)} placeHolder="Pick up?"
            autoValue={locationSlice?.origin?.address}

            desc=""
            className="flex-1" /></div>
        )}
      </div>
    );
  };

  const renderInputLocationDropoff = () => {
    const handleToData = async (e: any) => {
      setLocationInputDropOff(e.address);
      let url = SERVICE_URL.findAirport;
      let res = await callPostMethod(url, e);
      if (res.status == 200) {
        let response = res?.data[0]?.iata_code
        localStorage.setItem("toData", response)
        dispatch(UpdateFormFlightDetails({ ...locationSlice, destination: e }))


      }
    }
    const isActive = fieldNameShow === "locationDropoff";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationDropoff")}
          >
            <span className="text-neutral-400">Destination Address</span>
            <span>{locationInputDropOff || "Destination Address"}</span>
          </button>
        ) : (
          /* <LocationInput
             headingText="Drop off?"
             defaultValue={locationInputDropOff}
             onChange={(value) => {
               setLocationInputDropOff(value);
               setFieldNameShow("dates");
             }}
           /> */
          <div style={{ padding: '15px' }}><AutoSerachLocation setSelected={(e: number) => handleToData(e)}
            autoValue={locationSlice?.destination?.address}

            desc=""
            className="flex-1" /></div>
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">When</span>
            <span>
              {startDate
                ? converSelectedDateToString([locationSlice?.startDate, locationSlice?.endDate])
                : "Add date"}
            </span>
          </button>
        ) : (
          <DatesRangeInput selectsRange={dropOffLocationType !== "One-way"} />
        )}
      </div>
    );
  };

  const renderGenerals = () => {
    const isActive = fieldNameShow === "general";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("general")}
          >
            <span className="text-neutral-400">Flight type?</span>
            <span>{`${dropOffLocationType}, ${flightClassState}`}</span>
          </button>
        ) : (
          <div className="p-5">
            <span className="block font-semibold text-xl sm:text-2xl">
              Flight type?
            </span>
            <div className="relative mt-5">
              <div className="flex space-x-2">
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${dropOffLocationType === "Round-trip"
                    ? "bg-black shadow-black/10 shadow-lg text-white"
                    : "border border-neutral-300 dark:border-neutral-700"
                    }`}
                  onClick={(e) => setDropOffLocationType("Round-trip")}
                >
                  Round-trip
                </div>
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${dropOffLocationType === "One-way"
                    ? "bg-black text-white shadow-black/10 shadow-lg"
                    : "border border-neutral-300 dark:border-neutral-700"
                    }`}
                  onClick={(e) => setDropOffLocationType("One-way")}
                >
                  One-way
                </div>
              </div>

              <div className="mt-6">
                <label className="text-base font-semibold" htmlFor="">
                  Ticket Class:
                </label>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {renderRadio("class", "Economy", "Economy")}
                  {renderRadio("class", "Business", "Business")}
                  {renderRadio("class", "Multiple", "Multiple")}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderRadio = (
    name: string,
    id: string,
    label: string,
    defaultChecked?: boolean
  ) => {
    return (
      <div className="flex items-center ">
        <input
          defaultChecked={flightClassState === label}
          id={id + name}
          name={name}
          onChange={() => setFlightClassState(label)}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";
    let guestSelected = "";
    if (guestInput.guestAdults || guestInput.guestChildren) {
      const guest =
        (guestInput.guestAdults || 1) + (guestInput.guestChildren || 0);
      guestSelected += `${guest} guests`;
    }

    if (guestInput.guestInfants) {
      guestSelected += `, ${guestInput.guestInfants} infants`;
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("guests")}
          >
            <span className="text-neutral-400">Who</span>
            <span>{guestSelected || `Add guests`}</span>
          </button>
        ) : (
          <GuestsInput defaultValue={guestInput} />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="w-full space-y-5">
        {renderInputLocationPickup()}
        {/*  */}
        {renderInputLocationDropoff()}
        {/*  */}
        {/* {renderGenerals()} */}
        {/*  */}
        {/* {renderInputDates()} */}
        {/*  */}
        {/* {renderInputGuests()} */}
      </div>
    </div>
  );
};

export default FlightSearchForm2;
