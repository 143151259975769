import { createSlice } from '@reduxjs/toolkit';
 
const initialState = { 
    seatData:[],
    baggageData:[],
    mealData:[],
    selectMealItem:[]
}

export const seatSelectionSlice = createSlice({
    name: 'seat',
    initialState,
    reducers: {
        updateSeatData: (state, action) => { 
            state.seatData = action.payload
        },
        updateBaggageData: (state, action) => { 
            state.baggageData = action.payload
        },
        updateMealData: (state, action) => { 
            state.mealData = action.payload.mealData
        },
        updateMealItemVal: (state, action) => { 
            state.selectMealItem = action.payload.selectMealItem
        },
         
    }
});

export const { updateSeatData,updateBaggageData,updateMealData,updateMealItemVal} = seatSelectionSlice.actions;

export const SelectSeatData = (state) => state.seat.seatData;
export const SelectBaggageData = (state) => state.seat.baggageData;
export const SelectMealData = (state) => state.seat.mealData;
export const SelectMealItemValue = (state) => state.seat.selectMealItem;
 

export default seatSelectionSlice.reducer;