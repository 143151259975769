import { createSlice } from '@reduxjs/toolkit';
 
const initialState = {
    flightDetails: null,
    triggers: {
        startDate: null,
        endDate: null,
        origin: null,
        destination: null,
        guestDetails: {
            guestAdults: 1,
            guestChildren: 0,
            guestInfants: 0,
        }
    },
    pageRefreshFlag: null,
    seatAvailabilityToken: null,
    seatSelectionData:[],
    mealSelection:[],
    startDateFlag:false,
    flightData:[]

}

export const DestinationSlice = createSlice({
    name: 'destinations',
    initialState,
    reducers: {
        setFlightDetails: (state, action) => {
            // console.log(action.payload)
            state.flightDetails = action.payload
        },
        UpdateFormFlightDetails: (state, action) => {
             state.triggers.origin = action?.payload?.origin
            state.triggers.destination = action?.payload?.destination
            state.triggers.startDate = action?.payload?.startDate
            state.triggers.endDate = action?.payload?.endDate
            state.triggers.guestDetails = action?.payload?.guestDetails
        },
        updateFlightDetails: (state, action) => {
             state.pageRefreshFlag = action.payload
        },

        updateSeatAvialbliltyToken: (state, action) => {
             state.seatAvailabilityToken = action.payload
        },
        updateSeatSelection: (state, action) => {
             state.seatSelectionData = action.payload
        },
        updateStartDate: (state, action) => {
             state.startDateFlag = action.payload
        },
        updateFlightData: (state, action) => {
            state.flightData = action.payload
       },

    }
});

export const { setFlightDetails, UpdateFormFlightDetails, updateFlightDetails, updateSeatAvialbliltyToken,updateSeatSelection,updateStartDate,updateFlightData} = DestinationSlice.actions;

export const SelectFlightDetails = (state) => state.destinations.flightDetails;
export const SelectFormFlightDetails = (state) => state.destinations.triggers;
export const SelectUpdateFlightDetails = (state) => state.destinations.pageRefreshFlag;
export const SelectSeatAvailableToken = (state) => state.destinations.seatAvailabilityToken;
export const SelectSeatSelectionData = (state) => state.destinations.seatSelectionData;
export const SelectStartDateFlag = (state) => state.destinations.startDateFlag;
export const SelectFlightData = (state) => state.destinations.flightData;

export default DestinationSlice.reducer;