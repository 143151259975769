import SectionHero from "components/SectionHero/SectionHero";
import React, { useState, useEffect } from "react";
import { SERVICE_URL } from "config/api.url";
import './PageSeat.css';
import ic_flight from "../../images/ic_flight.png";
import ic_flightSmallTail from "../../images/ic_flightSmallTail.png";


import SeatNot from '../../images/carUtilities/seat_not_available_icon.png'
import { useDispatch, useSelector } from "react-redux";
import { SelectFormFlightDetails, SelectSeatAvailableToken, SelectSeatSelectionData, updateSeatSelection } from "store/slice/DestinationSlice";
import { callPostMethod } from "Services/api";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { SelectSeatData, updateSeatData } from "store/slice/seatSelectionSlice";


const PageSeat = () => {
    // const data = pageSetaData?.ExtraServices?.ExtraServiceDetails?.Seat[0];
    const dispatch = useDispatch()
    const locationSlice = useSelector(SelectFormFlightDetails)

    const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(parseInt(locationSlice?.guestDetails?.guestAdults));
    const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(parseInt(locationSlice?.guestDetails?.guestChildren));
    const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(parseInt(locationSlice?.guestDetails?.guestInfants));

    const [totalPassenger, setTotalPassenger] = useState(0);
    const [totalSeatBook, setTotalSeatBook] = useState([])
    const [loadState, setLoadState] = useState("load More");
    const [loaderFlag, setLoaderFlag] = useState(false)


    const resultToken = useSelector(SelectSeatAvailableToken)
    const seatData = useSelector(SelectSeatData)

    console.log(resultToken)

    // const [seatData, setSeatData] = useState([])

    useEffect(() => {
        if (seatData?.length == 0) { handleFilterData() }
    }, [seatData.length == 0, resultToken])

    useEffect(() => {
        const totalGuests =
            guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;
        setTotalPassenger(totalGuests)
    }, [])

    const handleFilterData = async () => {
        let payload = {
            ResultToken: resultToken
        }
        setLoaderFlag(true)
        let res = await callPostMethod(SERVICE_URL.flightSeatSelection, payload);
        if (res?.data?.Status == 1) {
            let data = res?.data?.ExtraServices?.ExtraServiceDetails?.Seat[0];
            console.log(data)
            let arr = []
            for (var i = 0; i < data.length; i++) {
                let arr1 = [];
                for (var k = 0; k < data[i].length; k++) {
                    arr1.push({ ...data[i][k], checked: false })
                }
                arr.push(arr1)
            }
            setLoaderFlag(false)
            dispatch(updateSeatData(arr))
        }
    }

    const handleBookedData = async (status, firstIndex, secondIndex, details) => {
        let seatVal = JSON.parse(JSON.stringify(seatData))
        let arr = totalSeatBook

        if (status) {
            seatVal[firstIndex][secondIndex].checked = false;
            let result = arr.filter((item, index) => item.SeatNumber !== details.SeatNumber)
            setTotalSeatBook(result) 
        } else {
            if (totalPassenger == totalSeatBook.length) {
                alert(`Seat is not greater than passenger`)
            } else {
                seatVal[firstIndex][secondIndex].checked = true;
                arr.push(details)
                setTotalSeatBook(arr)
            } 
        }
        dispatch(updateSeatData(seatVal))
    } 
    return (
        <div className="nc-PageHome relative overflow-hidden">
            <div className="head-01 ancillaryScrollWrap singleLengthWrap">
                <div className="scrollItem customScroll seats po01">
                    <div class="seatHeader"><div>
                        <p class="fontSize16 boldFont"><b>New Delhi → Bengaluru</b></p><p class="fontSize12 darkText">1 of 1 Seat(s) Selected</p></div><div class="makeFlex"><div class="makeFlex hrtlCenter"><span class="pendingTxt"></span></div></div></div>

                    <div className="seat-wrapper">
                        <div>
                            <span class="bgProperties iconflFront topf" style={{ backgroundImage: `url(${ic_flight})` }}></span>
                        </div>
                        <div className="seatmap-wrapper" >
                            <div className="flightSeatMatrix" >
                                <div className="seat_row wing-start wing-end">
                                    <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                        <div className="seat_empty_label"></div>
                                    </div>
                                    <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                        <div className="seat_label">A</div>
                                    </div>
                                    <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                        <div className="seat_label">B</div>
                                    </div>
                                    <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                        <div className="seat_label">C</div>
                                    </div>
                                    <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                        <div className="seat_empty_label"></div>
                                    </div>
                                    <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                        <div className="seat_label">D</div>
                                    </div>
                                    <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                        <div className="seat_label">E</div>
                                    </div>
                                    <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                        <div className="seat_label">F</div>
                                    </div>
                                    <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                        <div className="seat_empty_label"></div>
                                    </div>
                                </div>

                                {/* {loaderFlag && <div className="flex mt-12 items-center" style={{ marginLeft: '10%', marginTop: "10%" }}>
                                    <ButtonPrimary loading>{loadState}</ButtonPrimary>
                                </div>} */}

                                {seatData?.map((item, index) => {
                                    return (
                                        <div className="seat_row ">

                                            <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                                <div className="seat_label">{index + 1}</div>
                                            </div>

                                            {item.map((details, i) => {
                                                if (i == 3) {
                                                    return (
                                                        <>
                                                            <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                                                <div className="seat_empty_label"></div>
                                                            </div>
                                                            {/* <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                                    <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                                        className="font14 LatoBold seatLabel"></span></div>
                                                </div> */}
                                                            {details?.AvailablityType == 1 && details?.Price == 0 ?
                                                                <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                                                    <div className="seat_block cursor_pointer make_relative bg-color-2"><span
                                                                        className="font14 LatoBold seatLabel"></span></div>
                                                                </div> :
                                                                details?.AvailablityType == 0 ?
                                                                    <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                                                        <div className="seat-not-available">
                                                                            <img src={SeatNot} />
                                                                        </div>
                                                                    </div>
                                                                    : <div className="seat_col" style={{ width: '46px', height: '46px' }} onClick={() => handleBookedData(details.checked, index, i, details)}  >
                                                                        <div className={details?.checked ? "seat_block seat-selected" : "seat_block cursor_pointer make_relative bg-coror-1"}><span
                                                                            className="font14 LatoBold seatLabel"></span></div>
                                                                    </div>}

                                                        </>
                                                    )
                                                } else {

                                                    return (
                                                        <>
                                                            {details?.AvailablityType == 1 && details?.Price == 0 ?
                                                                <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                                                    <div className="seat_block cursor_pointer make_relative bg-color-2"><span
                                                                        className="font14 LatoBold seatLabel"></span></div>
                                                                </div> :
                                                                details?.AvailablityType == 0 ?
                                                                    <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                                                        <div className="seat-not-available">
                                                                            <img src={SeatNot} />
                                                                        </div>
                                                                    </div>
                                                                    : <div className="seat_col" style={{ width: '46px', height: '46px' }} onClick={() => handleBookedData(details.checked, index, i, details)}  >
                                                                        <div className={details?.checked ? "seat_block seat-selected" : "seat_block cursor_pointer make_relative bg-coror-1"}><span
                                                                            className="font14 LatoBold seatLabel"></span></div>
                                                                    </div>}

                                                        </>

                                                    )
                                                }
                                            })}
                                            <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                                                <div className="seat_label">{index + 1}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {/* <div><span class="bgProperties iconflTail bottomf"  style={{ backgroundImage: `url(${ic_flightSmallTail})` }}></span></div> */}
                            </div>



                            {/* <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">1</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="empty_cell"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="empty_cell"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available">
                                <img src={SeatNot} />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">1</div>
                        </div>
                    </div> */}
                            {/* <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">2</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="empty_cell"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="empty_cell"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">2</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">3</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="empty_cell"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="empty_cell"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">3</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">4</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">4</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">5</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available">
                                <img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1" ><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available">
                                <img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available">
                                <img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">5</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">6</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">6</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">7</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">7</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">8</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">8</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">9</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">9</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">10</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">10</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">11</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">11</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">12</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-04"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-04"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-04"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-04"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-04"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-04"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">12</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">15</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-04"><span
                                className="isRecExitRowSeat bg-color-3"></span><span
                                    className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-04"><span
                                className="isRecExitRowSeat bg-color-3"></span><span
                                    className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-04"><span
                                className="isRecExitRowSeat bg-color-3"></span><span
                                    className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-04"><span
                                className="isRecExitRowSeat bg-color-3"></span><span
                                    className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-04"><span
                                className="isRecExitRowSeat bg-color-3"></span><span
                                    className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-04"><span
                                className="isRecExitRowSeat bg-color-3"></span><span
                                    className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">15</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">16</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="isRecExitRowSeat bg-color-3"></span><span
                                    className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="isRecExitRowSeat bg-color-3"></span><span
                                    className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="isRecExitRowSeat bg-color-3"></span><span
                                    className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="isRecExitRowSeat bg-color-3"></span><span
                                    className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="isRecExitRowSeat bg-color-3"></span><span
                                    className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="isRecExitRowSeat bg-color-3"></span><span
                                    className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">16</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">17</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">17</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">18</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">18</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">19</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">19</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">20</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">20</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">21</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">21</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">22</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">22</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">23</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">23</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">24</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">24</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">25</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block seat-selected"><img
                                src="./MakeMyTrip-flight-seat_files/seat_selected_tick_icon.png" /></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">25</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">26</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">26</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">27</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">27</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">28</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">28</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">29</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-color-2"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-color-2"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">29</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">30</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-color-2"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-color-2"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">30</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">31</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat-not-available"><img src="./MakeMyTrip-flight-seat_files/seat_not_available_icon.png" />
                            </div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-color-2"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-color-2"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">31</div>
                        </div>
                    </div>
                    <div className="seat_row ">
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">32</div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-color-2"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_empty_label"></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-color-2"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_block cursor_pointer make_relative bg-coror-1"><span
                                className="font14 LatoBold seatLabel"></span></div>
                        </div>
                        <div className="seat_col" style={{ width: '46px', height: '46px' }}>
                            <div className="seat_label">32</div>
                        </div>
                    </div> */}
                        </div>
                    </div>
                </div>
                <br /><br /><br /><br />
                <ul className="legendWrap"><li class="makeFlex gap-x-15" style={{ width: '150px' }}><span className="box" style={{ backgroundColor: '#fff' }}></span><span class="fontSize12">Free</span></li><li className="makeFlex gap-x-15" style={{ width: '150px' }} ><span className="box" style={{ backgroundColor: '#fff' }}></span><span className="fontSize12">₹ 200-350</span></li><li className="makeFlex gap-x-15" style={{ width: '150px' }}><span className="box" style={{ backgroundColor: '#fff' }}></span><span className="fontSize12">₹ 1000</span></li></ul>

                <ul className="legendWrap" style={{ marginLeft: '170px' }}><li className="makeFlex gap-x-15" style={{ width: '150px' }}><span className="box"><span className="bgProperties icon21" ></span></span><span className="fontSize12">Exit Row Seats</span></li><li style={{ width: '150px' }} className="makeFlex gap-x-15"><span className="box"><span className="bgProperties icon21" ></span></span><span className="fontSize12">Non Reclining</span></li></ul>
            </div>
        </div>
    );
}

export default PageSeat;
