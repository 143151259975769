import SectionHero from "components/SectionHero/SectionHero";
import React, { useState, Fragment,  useEffect,FC} from "react";
import { SERVICE_URL } from "config/api.url";
import './PageMobileDetails.css';
import { useDispatch, useSelector } from "react-redux";
import { SelectFormFlightDetails, setFlightDetails, updateSeatAvialbliltyToken } from "store/slice/DestinationSlice";
import { callPostMethod } from "Services/api";
import moment from "moment";
import PageMeal from '../../PageMeal/PageMeal'
import PageSeat from '../../PageSeat/PageSeat'
import { useLocation, useNavigate } from "react-router-dom";
import { Amenities_demos, PHOTOS } from "./constants";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Tab } from "@headlessui/react";
import PageBaggage from "containers/PageBaggage/PageBaggage";


export interface FlightsDetailsPageProps {
    className?: string;
    data?: Object;
  }

const PageMobileDetails: FC<FlightsDetailsPageProps> = ({
    className = "",
    data = {},
  }) => {

    const dispatch = useDispatch();
    const locationSlice = useSelector(SelectFormFlightDetails)

    const [flightDestination, setFlightDestination] = useState<any>(null)
    const [priceDetails, setPriceDetails] = useState<any>(null);
    const [taxFare, setTaxFare] = useState<any>(0)
    const [token, setToken] = useState<any>(null)
    const flightDetailsVal = useSelector((state: any) => state.destinations.flightDetails)
    const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(parseInt(locationSlice?.guestDetails?.guestAdults));
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(parseInt(locationSlice?.guestDetails?.guestChildren));
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(parseInt(locationSlice?.guestDetails?.guestInfants));
  const [totalData, setTotalData] = useState<any>(0)

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  let [isOpenModalSeat, setIsOpenModalSeat] = useState(false);
  let [categories] = useState(["Seat", "Meal", "Baggage"]);

  useEffect(() => {
    let data=  localStorage.getItem("flightDetails");
      if(data!=undefined && data!=null){
       let token = JSON.parse(data) 
       dispatch(setFlightDetails(data))
      } 
    }, [])
//     const handleFlightDetails = async (e: string) => {
//       let url = SERVICE_URL.getFlightPriceDetails;
//       let payload = { "ResultToken": e }
//       let res = await callPostMethod(url, payload);
//       if (res?.status == 200) {
//         let response = res?.data?.UpdateFareQuote?.FareQuoteDetails?.JourneyList;
//         handlePriceBrakUpAdd(response?.Price?.PriceBreakup)
//         setPriceDetails(response?.Price)
//         let flightVal = Object.values(response?.FlightDetails?.Details)[0];
// console.log(flightVal)
//         setFlightDestination(flightVal)
//         dispatch(updateSeatAvialbliltyToken(response?.ResultToken))
//         setTimeout(() => {
//           dispatch(updateSeatAvialbliltyToken(response?.ResultToken))
//         }, 3000);
//       }
//     }
  
  
//     const handlePriceBrakUpAdd = async (value: any) => {
      
//       let add = value?.Tax + value?.AgentTdsOnCommision + value?.AgentCommission;
//        setTaxFare(add.toFixed(2));
//     }

//     useEffect(() => {
//         const totalGuests =
//     guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;
//     setTotalData(totalGuests)
//     }, [])

    function closeModalAmenities() {
        setIsOpenModalAmenities(false);
      }
    
      function openModalAmenities() {
        setIsOpenModalAmenities(true);
      }


      function closeModalSeat() {
        setIsOpenModalSeat(false);
      }
    
      function openModalSeat() {
        setIsOpenModalSeat(true);
      }


    const renderMotalAmenities = () => {
        return (
          <Transition appear show={isOpenModalAmenities} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-50 overflow-y-auto"
              onClose={closeModalAmenities}
            >
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
                </Transition.Child>
    
                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="inline-block py-8 h-screen w-full max-w-4xl">
                    <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                      <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                        <h3
                          className="text-lg font-medium leading-6 text-gray-900"
                          id="headlessui-dialog-title-70"
                        >
                          Amenities
                        </h3>
                        <span className="absolute left-3 top-3">
                          <ButtonClose onClick={closeModalAmenities} />
                        </span>
                      </div>
                      <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                        {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                          <div
                            key={item.name}
                            className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                          >
                            <i
                              className={`text-4xl text-neutral-6000 las ${item.icon}`}
                            ></i>
                            <span>{item.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        );
      };

      const renderMotalSeat = () => {
        return (
          <Transition appear show={isOpenModalSeat} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-50 overflow-y-auto"
              onClose={closeModalSeat}
            >
              <div className="min-h-screen text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
                </Transition.Child>
    
                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="inline-block h-screen w-full max-w-4xl">
                    <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                      <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                        <h3
                          className="text-lg font-medium leading-6 text-gray-900"
                          id="headlessui-dialog-title-70"
                        >
                          Add ON
                        </h3>
                        <span className="absolute left-3 top-3">
                          <ButtonClose onClick={closeModalSeat} />
                        </span>
                      </div>
                      <div className="px-2 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                <PageSeat />
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                <PageMeal />
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                    <PageBaggage />
                {/* {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                          <div
                            key={item.name}
                            className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                          >
                            <i
                              className={`text-4xl text-neutral-6000 las ${item.icon}`}
                            ></i>
                            <span>{item.name}</span>
                          </div>
                        ))} */}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
                            
          <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between" style={{width:'100%',position:'fixed',bottom:'0px'}}>
                      <button
                        type="button"
                        className="underline font-semibold flex-shrink-0">
                        Total Price 
                      </button>
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={closeModalSeat}
                      >
                        $ 100
                      </ButtonPrimary>
                    </div>
                    
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        );
      };


    return (
        <div className="revTvlrTopSection " style={{ background: 'linear-gradient(rgba(0, 16, 55, 0.84), rgb(0, 139, 222))' }}>
            <div className="rtHeader"><span className="reviewBackIcon"></span>
            <p className="rtHead">{flightDetailsVal?.flightInfo?.start_obj?.AirportCode}</p>
                <p className="rtSubHead">Trip to</p>
                <p className="rtHead">{flightDetailsVal?.flightInfo?.end_obj?.AirportCode}</p>
            </div>
            <div className="rtFlightDtl">
                <div>
                    <div className="rtFlightDtlRow">
                        <div className="flexOne">
                            <p className="make_flex space_between alnItemCenter append_bottom15">
                                <span
                                className="make_flex alnItemCenter">
                                    {/* <span className="airline-icon-sctn multiAirline--leftAligned abc"> <span
                                    src="https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi/I5.png?v=16"
                                    alt="airline"></span></span> */}
                                    <b>{flightDetailsVal?.OperatorName}</b></span></p>
                            <div className="make_flex space_between append_bottom20">
                                <div className="flightsInfoDtl">
                                    <p className="font20 LatoBolder append_bottom5">{moment(flightDestination?.Origin?.DateTime).format('LT')   // 10:31 PM
                                      }</p>
                                    <p className="font10 append_bottom8">{moment(flightDestination?.Origin?.DateTime).format('DD MMM YYYY')   // 10:31 PM
                                      }</p>
                                    <p className="LatoBlack append_bottom3">{flightDetailsVal?.flightInfo?.end_obj?.CityName}</p>
                                    {/* <p className="font12">
                                        <span>Indira Gandhi International Airport Terminal 3</span>
                                    </p> */}
                                </div>
                                <div>
                                    <div className="text_center">
                                        <p className="append_bottom5 font14 LatoBold">{flightDetailsVal?.flightInfo?.formattedTimeDifference}</p>
                                        <p className="append_bottom3"><span className="bgProperties stopsIcon"></span>
                                        </p>
                                        <p>1 stop</p>
                                    </div>
                                </div>
                                <div className="flightsInfoDtl text-right">
                                    <p className="font20 LatoBolder append_bottom5">{moment(flightDestination?.Destination?.DateTime).format('LT')   // 10:31 PM
                                      }</p>
                                    <p className="font10 append_bottom8">{moment(flightDestination?.Destination?.DateTime).format('DD MMM YYYY')   // 10:31 PM
                                      }</p>
                                    <p className="LatoBlack append_bottom3">{flightDetailsVal?.flightInfo?.start_obj?.CityName}</p>
                                    <p className="font12">
                                        {/* <span >Bengaluru International Airport Terminal 2</span> */}
                                    </p>
                                </div>
                            </div>
                            {/* <div className="layoverWrap append_bottom15">
                                <p className="make_flex alnItemStart append_bottom16"><span
                                    className="bgProperties icon16 append_right8"></span>
                                    <div className="make_flex column marL5"><span className="append_bottom5">
                                        <span color="#fff"><b>1h 40m</b> Layover at Pune</span>
                                    </span><span className="append_bottom5">
                                            <span color="#fff">00:15, 27 Oct - 01:55, 27 Oct</span>
                                        </span><span className="append_bottom5">
                                            <span color="#fff"><b>Plane change</b></span>
                                        </span></div>
                                </p>
                            </div> */}
                            {/* <p className="make_flex space_between alnItemCenter"><span className="make_flex alnItemCenter">Economy &gt;
                                Xpress Value</span></p> */}
                        </div>
                    </div>
                </div>
            </div>
            <p className="revTvlrWhiteCard append_bottom10">
                <p className="make_flex alnItemCenter space_between font18 LatoBlack blackTxt append_bottom20">Baggage Policy<span
                    className="blue-chevron"></span></p>
                <ul className="append_bottom10">
                    <li className="make_flex alnItemCenter blackTxt"><img
                        src="https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi/rt_rt3.png" width="24" height="24"
                        className="marR10" alt="baggageIcon" />
                        <div className="fullWidth make_flex"><span className="revTvlrBaggageInfo">Cabin bag</span><span dir="auto">{flightDestination?.Attr?.Baggage}</span></div>
                    </li>
                    <li className="make_flex alnItemCenter blackTxt"><img
                        src="https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi/rt_rt4.png" width="24" height="24"
                        className="marR10" alt="baggageIcon" />
                        <div className="fullWidth make_flex"><span className="revTvlrBaggageInfo">Check-in bag</span><span dir="auto">{flightDestination?.Attr?.Baggage}</span></div>
                    </li>
                </ul>
                <p className="addBaggageStrip append_bottom10"><span
                    className="make_flex alnItemCenter flexOne"><img className="marR10"
                        src="https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi/rt_rt14.png?v=5" width="24"
                        height="24" alt="addBaggageIcon" /><span className="flexOne">Got excess luggage? Don't stress, buy extra
                            check-in baggage allowance at fab rates!</span></span><span className="baggageLink" onClick={openModalSeat}>+ADD</span></p>
            </p>
        <div>
          {/* <ButtonSecondary onClick={openModalAmenities}>
            View more 20 amenities
          </ButtonSecondary> */}

        
          <ButtonSecondary onClick={openModalSeat}>
            Add On
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
        {renderMotalSeat()}
            
          
            <div className="rt_footer" id="black_footer">
                <div className="blackFooter" id="black_footer">
                    <div className="blackFooterLftDtls make_relative">
                        <p className="footerPrice"><span className="cc"><span>{`${priceDetails?.Currency || "₹"} ${priceDetails?.TotalDisplayFare.toFixed(2) || 0}`}</span></span><span className="greyInfoIcon marL5 font12"></span></p>
                        <div className="makeFlex">
                            <p className="footerPriceTxt">FOR {totalData} ADULT</p><span className="marR5"></span>
                        </div>
                    </div>
                    <div className="black-btn-wrpr"><a className="fltBtn  btnCapsule   ">CONTINUE</a></div>
                </div>
            </div>
        </div>
    );
}

export default PageMobileDetails;
