import SectionHero from "components/SectionHero/SectionHero";
import React, { useState, useEffect } from "react";
import { SERVICE_URL } from "config/api.url";
import './PageMeal.css';
import ic_flight from "../../images/ic_flight.png";
import ic_flightSmallTail from "../../images/ic_flightSmallTail.png";


import SeatNot from '../../images/carUtilities/seat_not_available_icon.png'
import { useDispatch, useSelector } from "react-redux";
import { SelectFormFlightDetails, SelectSeatAvailableToken, SelectSeatSelectionData, updateSeatSelection } from "store/slice/DestinationSlice";
import { callPostMethod } from "Services/api";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Meals from '../../images/meals_veg.avif'
import { SelectMealData, SelectMealItemValue, updateMealData, updateMealItemVal } from "store/slice/seatSelectionSlice";

const PageMeal = () => {
    // const data = pageSetaData?.ExtraServices?.ExtraServiceDetails?.Seat[0];
    const dispatch = useDispatch()
    const locationSlice = useSelector(SelectFormFlightDetails)

    const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(parseInt(locationSlice?.guestDetails?.guestAdults));
    const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(parseInt(locationSlice?.guestDetails?.guestChildren));
    const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(parseInt(locationSlice?.guestDetails?.guestInfants));


    const [loadState, setLoadState] = useState("load More");
    const [loaderFlag, setLoaderFlag] = useState(false)
    // const [mealSelection, setMealSelection] = useState([]);
    // const [mealData, setMealData] = useState([])

    const resultToken = useSelector(SelectSeatAvailableToken)
    const mealData = useSelector(SelectMealData)
    const mealSelection = useSelector(SelectMealItemValue)

    // const [seatData, setSeatData] = useState([])

    useEffect(() => {
        if (mealData?.length == 0) { handleFilterData() }
    }, [mealData,resultToken])

    useEffect(() => {
        const totalGuests =
            guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;
        // setTotalPassenger(totalGuests)
    }, [])

    const handleFilterData = async () => {
        let payload = {
            ResultToken: resultToken
        }
        setLoaderFlag(true)
        let res = await callPostMethod(SERVICE_URL.flightSeatSelection, payload);
        if (res?.data?.Status == 1) {
            let data = res?.data?.ExtraServices?.ExtraServiceDetails?.Meals;
            console.log(data)
            if (data !== undefined && data != null) {
                dispatch(updateMealData({mealData:data[0]}));
                dispatch(updateMealItemVal({selectMealItem:data}))

                // setMealData(data[0]);
            }
        }
    }

    const handleSelectionData = async (item) => {
        dispatch(updateMealItemVal({selectMealItem:item}))

    }


   

    return (
        <>
            {mealSelection?.length > 0 &&
                <div className="nc-PageHome relative overflow-hidden">
                    <div className="flightOverlayContent">
                        <div className=" ancillary-content">
                            <div className="baggage-wrapper">
                                <div className="sector-tabs-main hideScroll">
                                    <div className="sector-tabs">
                                        {mealSelection?.length > 0 && mealSelection?.map((item, index) => {
                                            return (
                                                <div className="sectorV1 active" key={index} data-index="0" onClick={() => handleSelectionData(item)}   >
                                                    <div className="ancillary_icon_wrpr">
                                                        {/* <img src="./MakeMyTrip-baggage_files/QP.png" width="30"
                                                height="30" /> */}
                                                    </div>
                                                    <div className="sectorTabDtls">
                                                        <p>{item[0]?.Origin}-{item[0]?.Destination}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div>
                                    <p className="ancillary-persuasion flexStart"><span>
                                        <font color="#1a7971"> Airline might levy extra charges for carrying more than 1 check-in bag
                                            per traveller.</font>
                                    </span></p>
                                    <div className="baggageMain scrollAuto">
                                        <div className="baggageInfoWrapper">
                                            <p className="font12 baggageInfo append_bottom5">Included Check-in baggage per person - <font
                                                color="#249995">15 KGS</font>
                                            </p>
                                        </div>

                                        {mealData?.length > 0 && mealData?.map((details, index) => {
                                            return (
                                                <div className="make_flex space_between baggage-item" key={index}>
                                                    <div key={details?.Description} className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8">
                                                        <i
                                                            className={`text-4xl text-neutral-6000 las la-utensils`}
                                                        ></i>
                                                        <span>{details?.Description}</span>
                                                        <span><p className="ancillary-counter"><span className="ancillary-counter-col LatoBold">ADD</span></p></span>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default PageMeal;
