import axios from "axios";

export const fetchFlights = (apiUrl,body) => { 
  return axios.post(apiUrl, body) 
};


export const callGetMethod = (url, token) => {
    return axios.get(url, { headers: getHeaders(token) })
}
export const callPostMethod = (url,payload,token) => {
    return axios.post(url, payload, { headers: getHeaders() })
}

export const callPostUploadMethod = (url,payload,header) => {
     return axios.post(url, payload, { headers: header})
}

export const callPatchMethod = (url,payload,token) => {
    return axios.patch(url, payload, { headers: getHeaders(token) })
} 

export const callPostMethodRajorPAy = (url,payload) => {
    return axios.post(url, payload, { headers: getHeadersRazorPay() })
}

export const callGetMethodRajorPay = (url) => {
    return axios.get(url, { headers: getHeadersRazorPay() })
}
export const getHeaders = (token) => {
    let headers = {};
    headers = {
        "Content-Type": "application/json",
        "Authorization": token ? token : '' 
    };
    return headers;
};

export const getUploadHeaders = (token) => {
    let headers = {};
    headers = {
        "Content-Type": "multipart/form-data",
        "Authorization": token ? token : '' 
    };
    return headers;
};
export const getHeadersRazorPay = () => {
    let headers = {};
    headers = {
        "Content-Type": "application/json",
        'Authorization': ''
    };
    return headers;
};
