import React, { FC, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import { callPostMethod } from "Services/api";
import './FlightsDetailsPage.css';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { SERVICE_URL } from "config/api.url";
 import PageSeat from "../PageSeat/PageSeat.jsx";
import { updateSeatAvialbliltyToken } from "store/slice/DestinationSlice";
import PageBaggage from "containers/PageBaggage/PageBaggage";
import PageMeal from "containers/PageMeal/PageMeal";
import FlightDetailsPageWeb from "./FlightDetailsPageWeb";
import PageMobileDetails from './PageMobileDetails/PageMobileDetails'
// import {fetchFlights} from "../../Services/api"

export interface FlightsDetailsPageProps {
  className?: string;
  data?: Object;
}

const FlightsDetailsPage: FC<FlightsDetailsPageProps> = ({
  className = "",
  data = {},
}) => {

  const dispatch = useDispatch();
  const [flightDestination, setFlightDestination] = useState<any>(null)
  const [priceDetails, setPriceDetails] = useState<any>(null);
  const [taxFare, setTaxFare] = useState<any>(0)
  const [token, setToken] = useState<any>(null)
  const flightDetailsVal = useSelector((state: any) => state.destinations.flightDetails)
  

  useEffect(() => {
  let data=  localStorage.getItem("flightDetails");
    if(data!=undefined && data!=null){
     let token = JSON.parse(data) 
      handleFlightDetails(token?.resultToken)
    } 
  }, [])

  const handleFlightDetails = async (e: string) => {
    let url = SERVICE_URL.getFlightPriceDetails;
    let payload = { "ResultToken": e }
    let res = await callPostMethod(url, payload);
    if (res?.status == 200) {
      let response = res?.data?.UpdateFareQuote?.FareQuoteDetails?.JourneyList;
      handlePriceBrakUpAdd(response?.Price?.PriceBreakup)
      setPriceDetails(response?.Price)
      let flightVal = Object.values(response?.FlightDetails?.Details)[0];
      setFlightDestination(flightVal)
      dispatch(updateSeatAvialbliltyToken(response?.ResultToken))
      setTimeout(() => {
        dispatch(updateSeatAvialbliltyToken(response?.ResultToken))
      }, 3000);
    }
  }


  const handlePriceBrakUpAdd = async (value: any) => {
    
    let add = value?.Tax + value?.AgentTdsOnCommision + value?.AgentCommission;
     setTaxFare(add.toFixed(2));
  }

  //let data = localStorage.getItem("flightDetails");
  return (
    <div
      className={`nc-FlightsDetailsPage relative overflow-hidden ${className}`}
      data-nc-id="FlightsDetailsPage"
    >
      {/* <FlightDetailsPageWeb /> */}
      <div className="mobile">
        <PageMobileDetails />
      </div>

      <div className="web">
        <FlightDetailsPageWeb />
      </div>
      
     
    </div>
  );
};

export default FlightsDetailsPage;
