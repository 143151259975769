import DatePicker from "react-datepicker";
import React, { FC, useEffect, useState } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { SelectFormFlightDetails, updateFlightDetails, UpdateFormFlightDetails } from "store/slice/DestinationSlice";
import { useDispatch, useSelector } from "react-redux";
import { addDays } from "date-fns";

export interface StayDatesRangeInputProps {
  className?: string;
  selectsRange?: boolean;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "",
  selectsRange = true
}) => {
  const [startDate, setStartDate] = useState<Date | null>(
    addDays(new Date(),7)
  );
  const locationSlice = useSelector(SelectFormFlightDetails);
  const dispatch = useDispatch()
  const [endDate, setEndDate] = useState<Date | null>( addDays(new Date(),10));

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    localStorage.setItem("startDate", String(formatDate(start)));
    localStorage.setItem("endDate", String(formatDate(end)));
    dispatch(UpdateFormFlightDetails({ ...locationSlice, startDate: start, endDate: end }))
    setStartDate(start);
    setEndDate(end);
  };

  const onSingleChangeDate = (dates: any) => {
    localStorage.setItem("startDate", String(formatDate(dates)));
    dispatch(UpdateFormFlightDetails({ ...locationSlice, startDate: dates, endDate: dates }))
    setStartDate(dates);
  };


 useEffect(() => {
   
  dispatch(UpdateFormFlightDetails({ ...locationSlice, startDate: startDate, endDate: endDate }))
   }, [])



  useEffect(() => {
     if (locationSlice?.startDate != null && locationSlice?.endDate !== null) { 
      setStartDate(new Date(locationSlice?.startDate));
      setEndDate(new Date(locationSlice?.endDate))
    }
  }, [])

  function formatDate(date: any) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {` When's your trip?`}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >
        {selectsRange ? <DatePicker
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          monthsShown={2}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        /> : <DatePicker
          selected={startDate}
          onChange={onSingleChangeDate}
          // monthsShown={1}
          // showPopperArrow={false}
          minDate={new Date()}
          inline
        // renderCustomHeader={(p) => (
        //   <DatePickerCustomHeaderTwoMonth {...p} />
        // )}
        // renderDayContents={(day, date) => (
        //   <DatePickerCustomDay dayOfMonth={day} date={date} />
        // )}
        />}
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
