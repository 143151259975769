import SectionHero from "components/SectionHero/SectionHero";
import React, { useState, useEffect } from "react";
import { SERVICE_URL } from "config/api.url";
import './PageBaggage.css';
import ic_flight from "../../images/ic_flight.png";
import ic_flightSmallTail from "../../images/ic_flightSmallTail.png";


import SeatNot from '../../images/carUtilities/seat_not_available_icon.png'
import { useDispatch, useSelector } from "react-redux";
import { SelectFormFlightDetails, SelectSeatAvailableToken, SelectSeatSelectionData, updateSeatSelection } from "store/slice/DestinationSlice";
import { callPostMethod } from "Services/api";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { SelectBaggageData } from "store/slice/seatSelectionSlice";
import { Amenities_demos } from "containers/FlightsDetailsPage/PageMobileDetails/constants";


const PageBaggage = () => {
    // const data = pageSetaData?.ExtraServices?.ExtraServiceDetails?.Seat[0];
    const dispatch = useDispatch()
    const locationSlice = useSelector(SelectFormFlightDetails)

    const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(parseInt(locationSlice?.guestDetails?.guestAdults));
    const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(parseInt(locationSlice?.guestDetails?.guestChildren));
    const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(parseInt(locationSlice?.guestDetails?.guestInfants));

    const [totalPassenger, setTotalPassenger] = useState(0);
    const [totalSeatBook, setTotalSeatBook] = useState([])
    const [loadState, setLoadState] = useState("load More");
    const [loaderFlag, setLoaderFlag] = useState(false)


    const resultToken = useSelector(SelectSeatAvailableToken)
    const baggageData = useSelector(SelectBaggageData)

    // const [seatData, setSeatData] = useState([])

    // useEffect(() => {
    //     handleFilterData()
    // }, [resultToken])

     


    return (
        <div className="nc-PageHome relative overflow-hidden">
            <div className="flightOverlayContent">

                {baggageData.length>0 &&baggageData.map((item,index) => {
                  
                    return (
                        <div
                            key={index}
                             className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                          >
                            <i
                              className={`text-4xl text-neutral-6000 las la-suitcase`}
                            ></i>
                            <span>{item.Weight} {item?.Price}</span>
                          </div>
                    )

                })}

                {/* {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                          <div
                            key={item.name}
                            className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                          >
                            <i
                              className={`text-4xl text-neutral-6000 las ${item.icon}`}
                            ></i>
                            <span>{item.name}</span>
                          </div>
                        ))}   */}

            </div>
        </div>
    );
}

export default PageBaggage;
