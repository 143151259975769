import { configureStore} from '@reduxjs/toolkit';
// import loginSlice from './slice/loginSlice'
// import createOrderSlice from './slice/createOrderSlice'
// import guildeSlice from './guideSlice';
import DestinationSlice from './slice/DestinationSlice';
import seatSelectionSlice from './slice/seatSelectionSlice'

export const store = configureStore({
    reducer: {
    //   login: loginSlice,
    //   createOrder:createOrderSlice
    // guide:guildeSlice,
    destinations:DestinationSlice,
    seat:seatSelectionSlice
    },
  })